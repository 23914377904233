import styled from 'vue-styled-components'

//TODO - Update button to align with advanced button styling
const PrimaryButton = styled.button`
    align-items: center;
    display: flex;
    margin-left: 10px;
    justify-content: center;
    height: 52px;
    background: #E60000;
    color: white;
    border:1px solid #E60000;
    box-shadow: none;
    border-radius: 200px;
    font-size:20px;
    letter-spacing: 0.1px;
    &:hover {
      cursor: pointer;
    }
    &:disabled {
      color: #A8ACB8;
      background: #393949;
      border: #393949;
      &:hover {
        cursor: not-allowed;
        box-shadow:none;
      }
    }
`;

const secondaryBtnProps = { border: String };

const SecondaryButton = styled('button', secondaryBtnProps)`
    align-items: center;
    height: 38px;
    display: flex;
    height: 52px;
    justify-content: center;
    background: transparent;
    color: white;
    box-shadow: none;
    border-radius: 200px;
    border: 1px solid white !important;
    font-size:20px;
    letter-spacing: 0.1px;
    &:hover {
        cursor: pointer;
    }
    &:disabled {
    color: #FFFFFF;
    opacity: 0.4;
    background: transparent;
    &:hover {
        cursor: not-allowed;
        box-shadow:none;
    }
    }
`


export {
    PrimaryButton,
    SecondaryButton,
}