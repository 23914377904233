<template>
<div>
    <div class="_spa-container" style="padding-bottom: 20px;height:calc(100vh - 20px)">
        <div style="display: flex;justify-content: center;align-items: center;flex-direction: column;margin-top:50px"> 
            <img src="/assets/spa/friends.svg" style="max-width: 252px;"/>
        </div>
        <div class="_wording-container" >
            <span class="_title" style="font-size: 26px;font-weight: 400;text-align: center;">Thank you for applying </span>
            <span class="_text" style="padding: 20px;">You will receive an SMS or call from the partner you have chosen with further instructions and details about your application.</span>
        </div>
        <div>
            <div style="display: flex;width: 100%;justify-content: space-between;flex-direction:column">
                <primary-button id="primary_nav" type="button" style="width: 100%;justify-content: center;margin-left:0px"  @click="close()">Close</primary-button>
                <secondary-button id="secondary_nav" type="button" style="width: 100%;justify-content: center;margin-left:0px;margin-top:10px"  @click="redirectToFaqs">FAQ's</secondary-button>
            </div>
        </div>
    </div>
        <el-dialog
            :visible.sync="dialogVisible"
            :modal-append-to-body="false"
            :append-to-body="true"
            :close-on-press-escape="false"
            :lock-scroll="true"
            :show-close="false"
            :close-on-click-modal="false"
            size="small"
            custom-class="_consent-dialog"
        >
            <img src="/assets/spa/lightbulb.svg"/>
            <span style="text-align:center">Please tap the exit icon on the top right of your screen to go to the VodaPay app.</span>
            <secondary-button id="secondary_nav" @click="dialogVisible = false" style="width:100%;color:#C9CCD4">Got It <img src="/assets/spa/icon-right.svg" style="height: 10px;width: auto;margin: 0px;margin-left: 10px;"/></secondary-button>
        </el-dialog> 
</div>
</template>

<script>
export default {
    created() {
         window.utag.view({
            event_name: 'page_view',
            page_name: 'vodapay: vodalend compare: thank you',
            page_section: 'vodalend compare',
            page_channel: 'mobile app',
            visitor_login_status: 'logged in',
            page_type: 'webview',
        });
    },
    data(){
        return {
            dialogVisible:false
        }
    },
    methods:{
        close() {
            window.utag.link({
                event_name: 'ui_interaction',
                page_name: 'vodapay: vodalend compare: thank you',
                page_section: 'vodalend compare',
                page_channel: 'mobile app',
                visitor_login_status: 'logged in',
                page_type: 'webview',
                link_id: 'vodapay: vodalend compare: close',
            });
            this.dialogVisible = true;
        },
        redirectToFaqs(){
            window.utag.link({
                event_name: 'ui_interaction',
                page_name: 'vodapay: vodalend compare: thank you',
                page_section: 'vodalend compare',
                page_channel: 'mobile app',
                visitor_login_status: 'logged in',
                page_type: 'webview',
                link_id: 'vodapay: vodalend compare: faqs',
            });
            this.$router.push('/faqs/credit')
        }
    }
}
</script>