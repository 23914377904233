import axios from 'axios'
// Constructor
const base_url = process.env.VUE_APP_GATHR_ENDPOINT// process.env.MIX_APP_URL - TODO update pipeline to compile assets

async function get(route, params = {}, userToken = null) {
    try {
        const tenant = process.env.VUE_APP_GATHR_TENANT;
        let token = process.env.VUE_APP_GATHR_KEY;
        if(userToken){
            token = userToken;
        }
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
        const { data } = await axios.get(`${base_url}/${tenant}/${route}`, {
            params: params
        })
        return data
    } catch (e) {
        // TODO Handle Errors
        console.log(e)
        throw e
    }
}

async function post(route, payload = {}, headers = {}) {
    try {
        const tenant = process.env.VUE_APP_GATHR_TENANT
        const token = process.env.VUE_APP_GATHR_KEY;
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
        const { data } = await axios.post(`${base_url}/${tenant}/${route}`, payload, {
            headers: {
                ...headers
            }
        })
        return data
    } catch (e) {
        // TODO Handle Errors
        console.log(e)
        throw e
    }
}

async function patch(route, payload = {}, headers = {}) {
    try {
        const tenant = process.env.VUE_APP_GATHR_TENANT
        const token = process.env.VUE_APP_GATHR_KEY;
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
        const { data } = await axios.patch(`${base_url}/${tenant}/${route}`, payload, {
            headers: {
                ...headers
            }
        })
        return data
    } catch (e) {
        // TODO Handle Errors
        console.log(e)
        throw e
    }
}


/**
 * Banks request
 */
export async function getBanks() {
    return await get('api/banks')
}
export async function createBankAccount(payload) {
    return await post('api/v0/bank-accounts', payload)
}

export async function getCustomer(id){
    return await get(`api/v1/customers?q=${id}`);
}

export async function createCustomer(payload){
    return await post(`api/v1/customers`, payload)
}

export async function updateCustomer(id, payload){
    return await patch(`api/v1/customers/${id}`, payload)
}

/**
 * Get Transactions 
 */
 export async function getTransactions(id, type){
    return await get(`api/v0/bank-accounts/${id}/transactions?type=${type}`)
}
/**
 * Get Summary 
 */
 export async function getSummary(id){
    return await get(`api/v0/bank-accounts/${id}/summary-plus?months=3`)
}

export async function submitSummaryData(id,data){
    return await post(`api/v0/bank-accounts/${id}/summary-data`,data)
}

/**
 * Statements request
 */
 export async function uploadStatement(payload,id){
    return await post(`api/v0/bank-accounts/${id}/upload-encoded-statement`,{
        name:'statement.pdf',
        statement: payload
    });
}

/**
 * Statements request
 */
 export async function verifyStatement(payload,id){
    return await post(`api/v0/bank-accounts/${id}/verify-encoded-statement`,{
        name:'statement.pdf',
        statement: payload
    });
}
/**
 * Confirm Application
 */

 export async function reportIssue(data){
    return await post(`api/v1/applications/report-issue`,data)
}

export async function getRepeatApplications(token){
    return await get(`api/v0/bank-accounts/check-repeat-bankaccount`,{},token)
}

export default {
    getBanks,
    getTransactions,
    createCustomer,
    getCustomer,
    updateCustomer,
    uploadStatement,
    getSummary,
    createBankAccount,
    submitSummaryData,
    reportIssue,
    getRepeatApplications,
    verifyStatement
}
