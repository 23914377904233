import Vue from 'vue';
import Vuex from 'vuex';
import application  from './modules/application';
import state  from './modules/state';
import banking  from './modules/banking';
import offers  from './modules/offers';

Vue.use(Vuex);

export const store = new Vuex.Store({
  modules: {
    application,
    state,
    banking,
    offers
  },
});
