<template>
    <div class="_spa-container" style="padding:0pc">
        <div :style="open ? 'height: calc(100vh + 42px); overflow: initial; width: 100vw;' : 'height: calc(100vh + 150px); overflow: hidden; width: 100vw;'">
            <div class="animated-content _employment" style="height:calc(100vh + 150px)">
                <div style="height: -webkit-fill-available;">
                    <div class="_wording-container">
                        <span class="_title">Tell us more about your employment</span>
                    </div>
                    <el-form :model="personalForm" ref="requirements"  :rules="rules" >
                        <fc-form-input
                            label="What is the name of your employer?"
                            prop="employer"
                            :error="errors.employer"
                            v-model="personalForm.employer"
                            placeholder="Finch Technologies"
                            :maxlength="30"
                            name="employer"
                        ></fc-form-input>
                        <fc-form-input
                            label="What year did you start working here?"
                            prop="employment_year"
                            :error="errors.employment_year"
                            v-model="personalForm.employment_year"
                            placeholder="2020"
                            inputmode="numeric"
                            type="tel"
                            pattern="[0-9]*"
                            name="employment_year"
                        ></fc-form-input>
                        <fc-form-input
                            label="Workplace contact number"
                            prop="employment_cell_phone"
                            :error="errors.employment_cell_phone"
                            v-model="personalForm.employment_cell_phone"
                            placeholder="021703444"
                            inputmode="numeric"
                            type="tel"
                            pattern="[0-9]*"
                            :maxlength="10"
                            name="employment_cell_phone"
                        ></fc-form-input>
                        <fc-form-input
                            label="Employment sector"
                            prop="employment_sector"
                            :error="errors.employment_sector"
                            v-model="personalForm.employment_sector"
                            placeholder="Financial technology"
                            name="employment_sector"
                            :setFocus="setFocus"
                        ></fc-form-input>
                        <fc-form-input
                            label="Which province is your employer located in?"
                            prop="employment_province"
                            :error="errors.employment_province"
                            v-model="personalForm.employment_province"
                            placeholder="Western Cape"
                            name="employment_province"
                            :setFocus="setFocus"
                        ></fc-form-input>
                    <div style="margin-top:30px">
                        <div style="display: flex;width: 100%;justify-content: space-between;">
                            <primary-button id="primary_nav" type="button" style="width: 100%;margin-left:0px;justify-content: center;" @click="submitLoanData" :disabled="!personalForm.employer || !personalForm.employment_cell_phone">
                                <button-loading-spinner v-if="loading"/> 
                                <span v-else>Next</span>
                            </primary-button>
                        </div>
                    </div>
                    </el-form>

                </div>

            </div>
            <div id="overlay" :style="open ? 'display:block' : 'display:none'"></div>
            <div class="hid-box" :style="open ? 'top:'+top+';    border-radius: 35px 35px 0px 0px;height:auto;z-index: 2;padding-top:18px' : 'top: 100%;z-index: 2;height:autoborder-radius: 35px 35px 0px 0px;padding-top:18px'">
                <span class="material-icons" style="color: #C9CCD4;width: 100%;text-align: right;" @click="open = false">
                    close
                </span>
                <template v-if="type ==  'employment_sector'">
                    <span>Please select employment sector</span>
                    <div v-for="(item,index) in sections.employment_sector" id="selector" :selection="item.name" v-bind:key="index" style="width:100%" @click="setValue('employment_sector',item.label)">
                        <span style="margin-top: 20px;display: flex;width: 100%;justify-content: space-between;align-items: center;">{{item.label}} <img src="/assets/spa/icon-right.svg" style="height:10px"/></span>
                        <div class="divider" style="width: 100%;border-bottom: 1px solid #A8ACB8;margin-top: 20px;"></div>
                    </div>
                </template>
                <template v-if="type ==  'employment_length'">
                    <span>How long have you been employed here?</span>
                    <div v-for="(item,index) in sections.employment_time" id="selector" :selection="toKebab(item.name)" v-bind:key="index" style="width:100%; min-height:62px" @click="setValue('employment_length',item.label)">
                        <span style="margin-top: 20px;display: flex;width: 100%;justify-content: space-between;align-items: center;">{{item.label}} <img src="/assets/spa/icon-right.svg" style="height:10px" v-if="item.label"/></span>
                        <div class="divider" style="width: 100%;border-bottom: 1px solid #A8ACB8;margin-top: 20px;"  v-if="item.label"></div>
                    </div>
                </template>

                <template v-if="type ==  'employment_province'">
                    <span>Please select your province?</span>
                    <div v-for="(item,index) in sections.employment_province" id="selector" :selection="toKebab(item.value)" v-bind:key="index" style="width:100%" @click="setValue('employment_province',item.value)">
                        <span style="margin-top: 20px;display: flex;width: 100%;justify-content: space-between;align-items: center;">{{item.label}} <img src="/assets/spa/icon-right.svg" style="height:10px"/></span>
                        <div class="divider" style="width: 100%;border-bottom: 1px solid #A8ACB8;margin-top: 20px;"></div>
                    </div>
                </template>

            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { mapFields, validatePhone, validateEmploymentYear } from '../helpers.js' 
export default {
    data(){
        return {
            loading: false,
            rules: {
                employment_sector: [
                    {
                        required: true,
                        message: "Please input a value",
                        trigger: "change",
                    },
                ],
                employer: [
                    {
                        required: true,
                        message: "Please input a value",
                        trigger: "change",
                    },
                ],
                employment_province:[
                    {
                        required: true,
                        message: "Please input a value",
                        trigger:"change"
                    }
                ],
                employment_time:[
                    {
                        required:true, 
                        message:"Please input a value",
                        trigger:"change"
                    }
                ],
                employment_year:{
                    required:true,
                    validator:validateEmploymentYear,
                    trigger:"blur"
                },
                employment_cell_phone: [
                    {
                        required: true,
                        message: 'Please input your work cell phone number',
                        trigger: 'blur',
                    },
                    {
                        required:true,
                        validator:validatePhone,
                        trigger:"blur"
                    }
                ],
            },
            errors:{},
            open:false,
            type:'',
            top:"-100%",
            sections:{
            employment_province:[
                {label:'Eastern Cape',value:'Eastern Cape'},
                {label:'Free State',value:'Free State'},
                {label:'Gauteng',value:'Gauteng'},
                {label:'KwaZulu-Natal',value:'KwaZulu-Natal'},
                {label:'Limpopo',value:'Limpopo'},
                {label:'Mpumalanga',value:'Mpumalanga'},
                {label:'Northern Cape',value:'Northern Cape'},
                {label:'North West',value:'North West'},
                {label:'Western Cape',value:'Western Cape'},
            ],
            employment_time:[
                {name:'<3 months',label:'Less than 3 months'},
                {name:'3-6months',label:'3 to 6 months'},
                {name:'>6 months',label:'More than 6 months'},
                {label:'',name:''}
            ],
            employment_sector:[

                {
                    "name": "advertising-and-media",
                    "label": "Advertising and Media"
                },
                {
                    "name": "agriculture",
                    "label": "Agriculture"
                },

                {
                    "name": "cleaning",
                    "label": "Cleaning"
                },
                {
                    "name": "construction",
                    "label": "Construction"
                },

                {
                    "name": "education",
                    "label": "Education"
                },
                {
                    "name": "financial-services",
                    "label": "Financial Services"
                },
                {
                    "name": "government-and-public-sector",
                    "label": "Government and Public Sector"
                },
                {
                    "name": "healthcare-and-beauty",
                    "label": "Healthcare and Beauty"
                },
                {
                    "name": "hotels-and-restaurants",
                    "label": "Hotels and Restaurants"
                },
                {
                    "name": "information-technology",
                    "label": "Information Technology"
                },

                {
                    "name": "legal-services",
                    "label": "Legal Services"
                },

                {
                    "name": "leisure",
                    "label": "Leisure, Travel and Tourism"
                },
                {
                    "name": "manufacturing",
                    "label": "Manufacturing"
                },
                {
                    "name": "marketing-and-public-relations",
                    "label": "Marketing and Public Relations"
                },

                {
                    "name": "mining",
                    "label": "Mining"
                },

                {
                    "name": "other",
                    "label": "Other"
                },
                {
                    "name": "property",
                    "label": "Property"
                },

                {
                    "name": "retail",
                    "label": "Retail"
                },
                {
                    "name": "security",
                    "label": "Security"
                },
                {
                    "name": "transport-and-logistics",
                    "label": "Transport and Logistics"
                },
                {
                    "name": "telecommunications",
                    "label": "Telecommunications"
                },
            ]
            }
        }
    },
    computed:{
        ...mapState({
            personalForm: state => state.application.personalLoan,
        }),
        ...mapFields({
            fields: ['employer','employment_sector', 'employment_time','employment_cell_phone','employment_province'],
            store: 'application',
            fieldMap: 'information',
            target: 'personalForm',
            mutation: 'updatePersonalLoanField'
        }),
    },
    created() {
         window.utag.view({
            event_name: 'page_view, form_start',
            page_name: 'vodapay: vodalend compare: employment details',
            page_section: 'vodalend compare',
            page_channel: 'mobile app',
            visitor_login_status: 'logged in',
            page_type: 'webview',
            form_name: 'employement details'
        });
    },
    methods:{
        submitLoanData(){
            this.$refs['requirements'].validate(async valid => {
                if(valid){
                    window.utag.link({
                        event_name: 'ui_interaction, form_end',
                        page_name: 'vodapay: vodalend compare: employment details',
                        page_section: 'vodalend compare',
                        page_channel: 'mobile app',
                        visitor_login_status: 'logged in',
                        page_type: 'webview',
                        link_id: 'vodapay: vodalend compare: view offers',
                        form_name: 'employement details',
                    });
                    this.loading = true
                    let res = await this.$store.dispatch('offers/fetchOffers') 
                    this.loading = false
                    this.$router.push({name:res})
                }
            })
        },
        async setValue(type,val){
            await this.$store.dispatch('application/updatePersonalLoanField',{
                key:type,
                value: val
            })
            this.open = false
        },
        setFocus(type){
            if(type == 'employment_sector'){
                this.top = '-80%'
            } else if(type == 'employment_length'){
                this.top = '-60%'
            } else if(type == 'employment_province'){
                this.top = '-86%'
            }
            this.open = true;
            this.type = type;
        },
        toKebab(string) {
            return string
                .split('')
                .map((letter) => {
                if (/[A-Z]/.test(letter)) {
                    return ` ${letter.toLowerCase()}`
                }
                return letter
                })
                .join('')
                .trim()
                .replace(/[_\s]+/g, '-')
        }

    },

}
</script>
<style lang="scss">
._employment{
    .el-form-item{
        .el-form-item__label{
            font-size: 14px !important;
        }
    }
}

</style>