<template>
    <div class="__navigation-bar" style="min-height:14px">
        <!-- <div class="_header-text">
            <i class="material-icons" style="font-size: 30px;padding: 10px;color: white;cursor:pointer" @click="goBack">keyboard_arrow_left</i>
            <span>VodaLend Marketplace</span>
        </div> -->
        <el-progress :percentage="this.progress" v-if="!ignorePaths.includes(currentPath)"></el-progress>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
    computed: {
        ...mapGetters('state', {
            status: 'status',
        }),
        ...mapGetters('application', {
             type: 'type',
        })
    },
    data(){
        return {
            progress:5,
            currentPath:'',
            ignorePaths:[
                '/technical-error',
                '/success-sms',
                '/no-offers',
                '/terms-and-conditions/boodle',
                '/privacy-policy/boodle'
            ],
            id:'',
            applyNow:['/start','/application-method,','/loan-information','/primary-income','/personal-information','/select-bank','/select-upload','/online-banking','/upload-statements','/faqs','/faqs/online','/income-confirmed','/consent','/employment','/offers','/finalize'],
            getQuote:['/start','/application-method,','/loan-information','/primary-income','/personal-information','/consent','/employment','/offers','/finalize']
        }
    },
    watch: {
        '$route': {
            handler (route) {
                this.id = route.params.bankAccountId;
                let path = route.path
                if(path == '/unemployed'){
                    path = '/primary-income'
                }
                if(path == '/application-method'){
                    this.progress = 10
                }else if(path == '/start'){
                    this.progress = 5
                }else{
                    if(this.type == 'apply-now'){
                        let i = this.applyNow.indexOf(path) + 1;
                        let length = this.applyNow.length;
                        let percentage = (i / length) * 100;
                        this.progress = percentage
                    }else{
                        let i = this.getQuote.indexOf(path) + 1;
                        let length = this.getQuote.length;
                        let percentage = (i / length) * 100;
                        this.progress = percentage     
                    }
                }
                this.currentPath = path;
            },
            immediate: true

        },
        
        'type': {
            handler (type) {
                console.log(type)
            },
            immediate: true // make this watch function is called when component created
        }
    },
    methods:{
        goBack(){
            if(this.status !== 'start' && this.status !== 'success'){
                this.$router.go(-1)
            }
 
        }
    }
}
</script>
<style lang="scss">
.__navigation-bar{
    background: #08081B;
    ._header-text{
        display: flex;
        padding-right: 50px;
        align-items: center;
        span{
            margin: 0 auto;
            color: #C9CCD4;
            font-size: 16px;
        }
    }
    .el-progress {
        .el-progress__text{
            display: none;
        }
        .el-progress-bar{
            padding-right: 0px;
            .el-progress-bar__outer{
                border-radius: 0px !important;
                background: #525365 !important;
            }
            .el-progress-bar__inner{
                border-radius: 0px !important;
                background-color: #E60000 !important;
            }
        }
    }
}
</style>