<template>
    <div class="_spa-container">
        <div>
            <div class="_wording-container">
                <span class="_title">Please select your upload method</span>
                <span class="_text">This is the way we will retrieve your statements. Online login is the fastest and easiest method.</span>
            </div>
            <el-form :model="personalForm" ref="requirements" >
                <div class="_custom-radio" style="padding-bottom:20px;margin-top: 25px;">
                    <div class="_radio">
                        <div class="_text-section">
                            <img class="_main-img" src="/assets/spa/apply-now.svg"/>
                            <span>Online Banking</span>
                        </div>
                        <div>
                            <img class="_tick-svg" src="/assets/spa/tick-on.svg" v-if="upload_method == 'online'" @click="upload_method = false"/>
                            <img class="_tick-svg" src="/assets/spa/tick-off.svg" v-else @click="upload_method = 'online' "/>
                        </div>
                    </div>
                    <div class="_radio">
                        <div class="_text-section">
                            <img class="_main-img" src="/assets/spa/document.svg"/>
                            <span>Manual Upload</span>
                        </div>
                        <div>
                            <img class="_tick-svg" src="/assets/spa/tick-on.svg" v-if="upload_method == 'statement'" @click="upload_method = false"/>
                            <img class="_tick-svg" src="/assets/spa/tick-off.svg" v-else @click="upload_method = 'statement' "/>
                        </div>
                    </div>
                </div>
            </el-form>
        </div>
        <div>
            <div class="_secured-container">
                <div class="secured-spacer">
                    <div class="_divider"></div>
                    <img src="/assets/spa/secured.svg"/>
                    <div class="_divider"></div>
                </div>
                <span>We use industry-leading encryption and security methods when handling your documents. Your details are not shared with anyone.</span>
            </div>
            <div class="_button-container">
                <secondary-button id="secondary_nav" @click="redirectToFaqs" style="width:40%">FAQ's</secondary-button>
                <primary-button id="primary_nav" @click="updateType" style="width: 60%;justify-content: center;margin-left:10px" :disabled="!upload_method">
                    <button-loading-spinner v-if="loading"/> 
                    <span v-if="!loading">Next</span>
                </primary-button>
            </div>
        </div>

    </div>

</template>
<script>
import { mapGetters, mapState } from 'vuex'
export default {
    data(){
        return {
            loading:false,
            upload_method:'online'
        }
    },
    computed:{
        ...mapState({
            personalForm: state => state.application.personalLoan,
        }),
        ...mapGetters('state', {
            banks: 'banks',
        }),
    },
    created() {
         window.utag.view({
            event_name: 'page_view',
            page_name: 'vodapay: vodalend compare: bank statement upload method',
            page_section: 'vodalend compare',
            page_channel: 'mobile app',
            visitor_login_status: 'logged in',
            page_type: 'webview',
        });
    },
    methods: {
        async updateType() {
            try {
                this.loading = true;
                this.$store.dispatch('application/updatePersonalLoanField',{
                    key:'upload_method',
                    value:this.upload_method
                })
                window.utag.link({
                    event_name: 'ui_interaction',
                    page_name: 'vodapay: vodalend compare: bank statement upload method',
                    page_section: 'vodalend compare',
                    page_channel: 'mobile app',
                    visitor_login_status: 'logged in',
                    page_type: 'webview',
                    link_id: 'vodapay: vodalend compare: next',
                });
                let bankAccount = await this.$store.dispatch('application/createBankAccount')
                await this.$store.dispatch('application/setBankAccount',{ bankAccount, repeat: false })
                if(this.upload_method == 'statement'){
                    await this.$store.dispatch('state/updateStatus', 'upload-statements')
                    await this.$router.push({ name: 'upload-statements' })
                }else{
                    await this.$store.dispatch('state/updateStatus', 'online-banking')
                    await this.$router.push({ name: 'online-banking' })
                }

            } catch (e) {
                if(e.response && e.response.data && Array.isArray(e.response.data.errors) && e.response.data.errors[0].includes('leads_email_unique')){
                    await this.$router.push({ name: 'duplicate-email' })
                }else{
                    await this.$router.push({ name: 'technical-error' })
                }
            }
        },
        redirectToFaqs(){
             window.utag.link({
                event_name: 'ui_interaction',
                page_name: 'vodapay: vodalend compare: bank statement upload method',
                page_section: 'vodalend compare',
                page_channel: 'mobile app',
                visitor_login_status: 'logged in',
                page_type: 'webview',
                link_id: 'vodapay: vodalend compare: faqs',
            });
            this.$store.dispatch('state/updateStatus', 'faqs')
            this.$router.push('faqs/online')
        },
    }
}
</script>