import styled from 'vue-styled-components'

const StyledFormWrapper = styled.div`
width: 100%;
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus{
  background:transparent
  color:white !important
}
.login-input{
  .el-form-item__label{
    margin-left: 20px;
    color: #A8ACB8 !important;
    margin-bottom: 10px !important;
  }
}
.currency-input{
    .el-input__inner{
        margin-left:34px !important
        font-size: 32px !important;
        height:60px
        padding:12px 6px 4px 15px !important
    }
    .el-input__prefix{
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        border-bottom: 1px solid #6D6F81;
        margin-left: 20px;
        font-size: 20px;
    }
    .el-form-item__label{
      color: #A8ACB8 !important;
      font-size: 16px !important;
    }
}
.fc-input{
  
    .el-input{
        background: #222739;
        border-radius: 5px;
        display: flex
    }
    @media(max-width:640px){
      margin: 0px;
    }
    
    position: relative;
    margin-top: 60px !important;

    .el-input__inner {
        padding: 12px 6px 10px 0px;
        /* width: 88%; */
        height:50px;
        margin-right: 20px;
        border-bottom: 1px solid #6D6F81 !important;
        /* margin: 0 auto; */
        margin-left: 20px;
        font-size: 20px;
        color: white;
        border: none;
        box-shadow: none;
        border-radius: 0px !important;
    }

    &.is-required {
      &.empty {
        .el-form-item__label {
          &:after {
            content: '*';
            color: #E60000 !important;
            margin-left: 4px;
          }
        }
        
        .material-icons, .material-icons-outlined  {
          margin-left: 10px;
        }
      }
      .el-form-item__label:before {
        content: "";
        margin: initial;
      }
    }

    &.focus {
      .el-input__inner {
        border-bottom: 1px solid #E60000 !important;
        border-color: #E60000 !important;
      }
      .el-form-item__label {
        color: #E60000 !important;
      }
    }

    &.empty .el-form-item__label {
      bottom: 10px;
      left: 0;
      font-size: 16px;
    }

    .el-form-item__label {
      transition: all .3s ease;
      position: absolute;
      bottom: 90%;
      left: 0;
      text-align: right;
      vertical-align: middle;
      float: left;
      padding: 0 12px 0 0;
      box-sizing: border-box;
      font-size: 14px;
      line-height: 150%;
      display: flex;
      align-items: center;
      letter-spacing: -0.01em;
      font-feature-settings: 'salt' on;
      color: #D8DBE0;
    }

    input {
      background-color: transparent !important;
    }
}
`;

export {
  StyledFormWrapper,
}