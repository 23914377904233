<template>
    <div class="loading" style="width:30px;height:30px">
        <svg width="30" height="30" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.5" fill-rule="evenodd" clip-rule="evenodd" d="M21.4453 2.65059C21.8324 4.25974 20.8417 5.87799 19.2326 6.26506C14.4995 7.40357 10.3419 10.6446 8.21669 15.418C4.58459 23.5758 8.2602 33.1511 16.418 36.7832C24.5759 40.4153 34.1511 36.7397 37.7832 28.5818C38.1932 27.661 38.5094 26.7244 38.7369 25.7824C39.1253 24.1736 40.7444 23.1843 42.3532 23.5727C43.9621 23.9612 44.9513 25.5803 44.5629 27.1891C44.2506 28.4827 43.8173 29.7645 43.2585 31.0196C38.2801 42.2013 25.162 47.2369 13.9803 42.2584C2.79852 37.28 -2.23703 24.162 2.74141 12.9802C5.65725 6.43111 11.3677 1.9925 17.8309 0.437831C19.44 0.0507592 21.0583 1.04145 21.4453 2.65059Z" fill="#F2F4F7"/>
            <path d="M32.0189 1.74134C33.5298 2.41404 34.2104 4.18696 33.5377 5.69786C32.865 7.20877 31.092 7.88933 29.5811 7.21663C28.0702 6.54393 27.3897 4.77101 28.0624 3.2601C28.7351 1.7492 30.508 1.06864 32.0189 1.74134Z" fill="#F2F4F7"/>
        </svg>
    </div>
</template>

<script>

export default {
    props: ["width"],
    components: {
    }
};
</script>

<style lang="scss">
.loading:hover path {
    fill: red;
}

.loading {
    -webkit-animation-name: spin;
    -webkit-animation-duration: 2000ms;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    -moz-animation-name: spin;
    -moz-animation-duration: 2000ms;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;
    -ms-animation-name: spin;
    -ms-animation-duration: 2000ms;
    -ms-animation-iteration-count: infinite;
    -ms-animation-timing-function: linear;
    animation-name: spin;
    animation-duration: 2000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

@-ms-keyframes spin {
    from {
        -ms-transform: rotate(0deg);
    }
    to {
        -ms-transform: rotate(360deg);
    }
}

@-moz-keyframes spin {
    from {
        -moz-transform: rotate(0deg);
    }
    to {
        -moz-transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    from {
        -webkit-transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
</style>
