<template>
<div class="_spa-container" :style="loading ? 'justify-content: center;height:100%;' : 'height:100%;'">
    <div v-if="loading">
        <loading-spinner :width="100" />
        <div class="_wording-container" style="max-width: 400px;margin: 0 auto;">
            <span class="_text">Statements uploaded! We are now checking your bank statements.</span>
        </div>
    </div>
    <div v-else-if="displayFaq" class="_faq-statement-container">
        <div class="_wording-container" style="padding:0px">
            <img src="/assets/svg/drop-book.svg" style="width: 120px;padding: 20px;"/>
            <span class="_title">Tips for Manually Uploading Bank Statements</span>
        </div>
        <div class="_statement-list">
            <div class="_s-item">
                <img src="/assets/svg/red-tick.svg"/>
                <span>Must be in PDF format</span>
            </div>
            <div class="_s-item">
                <img src="/assets/svg/red-tick.svg"/>
                <span>Must be an original bank statement (no transactional history documents)</span>
            </div>
            <div class="_s-item">
                <img src="/assets/svg/red-tick.svg"/>
                <span>You need to upload your most recent 3 months bank statements</span>
            </div>
            <div class="_s-item">
                <img src="/assets/svg/red-tick.svg"/>
                <span>Give Vodapay App permission to access files and documents</span>
            </div>
        </div>
        <div class="_button-container">
            <secondary-button @click="redirectToFaqs" style="width:40%">FAQ's</secondary-button>
            <primary-button type="button" @click="displayFaq = false" style="width: 60%;margin-left:10px;justify-content: center;">Upload</primary-button>
        </div>
    </div>
    <div class="statement-upload-container" v-else-if="!displayError && !displayFaq" >
        <div class="_statement-content">
            <div class="_content" style="justify-content:flex-start"  >
                <div class="_wording-container">
                    <span class="_title">Statement Upload</span>
                    <span class="_text">You will need to upload files which you have already downloaded from your banking app</span>
                </div>
                <div class="row" style="width: 100% !important;max-width: 750px;margin: 0px auto; margin-top:30px">
                <div class="col-md-12" v-if="initialStep">
                    <div class="_add-file-container">
                        <button class="_add-file-btn" @click="initiate" style="color:white;">
                            <span>Add Files</span>
                            <i class="el-icon-plus"></i>
                        </button>
                    </div>
                </div>
                <table width="100%" class="__statements-table" :key="key" style="margin-top:30px"> 
                        <colgroup>
                        <col span="1" style="width: 30%;">
                        <col span="1" style="width: 25%;">
                        <col span="1" style="width: 15%;">
                        </colgroup>
                    <thead>
                    <tr>
                        <th>File Name</th>
                        <th>Month</th>
                        <th>Status</th>
                    </tr>
                    </thead>
                    <tbody v-if="!statementLoading">
                        <template v-for="(statement,index) in uploadedStatements">
                            <tr :style="statement.error && 'border-bottom:none'" v-bind:key="'file_name'+index">
                                <td>
                                    <div class="truncate">
                                        <div class="truncated">
                                            <h1>
                                                {{ statement.filename }}
                                            </h1>
                                        </div>
                                    </div>
                                    </td>
                                <td>{{ statement.month }}</td>
                                <td>
                                <div v-if="statement.error" class="_tag">
                                    <img src="/assets/spa/error-statement.svg"/>
                                </div>
                                <div v-else-if="statement.loading" class="_tag">
                                    <loading-spinner :width="25"/>
                                </div>
                                <div v-else class="_verified-container">
                                    <img src="/assets/spa/round-success.svg" style="height: 20px;"/>
                                </div>
                                </td>
                            </tr>
                        </template>
                    </tbody>
                    <tbody v-else>
                    <tr>
                        <td colspan="3">
                        <loading-spinner :width="100"/>
                        </td>
                    </tr>
                    </tbody>
                </table>
                </div>
                
                <div style="margin-bottom: 66px;"></div>
            </div>
            <div class="_button-container" style="flex-direction: column;">
                <div style="margin: 0px auto;display: flex;flex-direction: column;width: 100%;padding-bottom:20px">
                    <div class="_add-file-container" v-if="!initialStep && !statementsCompleted">
                        <label for="files" class="_add-file-btn" @click="initiate">
                            <span>Add Files</span>
                            <i class="el-icon-plus"></i>
                        </label>
                    </div>
                    <div class="_statement_length-container" v-if="!initialStep && !statementsCompleted">
                        <span class="circle">{{3 - uploadedStatements.length}}</span>
                        <span style="margin-top: 5px;font-weight: 300;font-size: 17px;color: #D8DBE0;">Documents Remaining</span>
                    </div>
                    <div class="error-statement" v-if="statementError">
                        <img src="/assets/spa/error-warning.svg"/>
                        <span>{{statementError}}</span>
                    </div>
                </div>
                <primary-button type="button" @click="submitStatements()" :disabled="!statementsCompleted" style="width: 100%;justify-content: center;margin-left:0px">Next</primary-button>
            </div>
        </div>
    </div>
    <online-error  v-else-if="displayError" :message="error" :displayReportError="false" :code="error.code ? error.code : false" :retry="retryUpload"/>
</div>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import api from '../api'
import moment from 'moment'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'
Bugsnag.start({
  apiKey: '7b50a5f7b319975d91f49410a9599507',
  plugins: [new BugsnagPluginVue()]
})
export default {
    data() {
        return {
            message:'',
            id: this.$route.params.bankAccountId,
            form: {},
            uploadedStatements:[],
            bankStatements: [],
            incomeCompleted:false,
            statementLoading:false,
            statementData:{},
            error:'',
            key:0,
            loading:false,
            innerStep:'',
            displayError:false,
            displayFaq:true,
            errors:{},
            errorCount:0,
            handlingStatement:false,
            verifiedStatementCount:0,
            statementError:'',
            fileList:[],
            statementsCompleted:false,
            allowUpload:false,
            initialStep:true
        }
    },
    computed:{
        ...mapGetters('state', {
            status: 'status'
        }),
        ...mapState({
            bankAccount: state => state.application.bankAccount,
        }),
    },
    created() {
        window.utag.view({
            event_name: 'page_view',
            page_name: 'vodapay: vodalend compare: bank statement manual upload',
            page_section: 'vodalend compare',
            page_channel: 'mobile app',
            visitor_login_status: 'logged in',
            page_type: 'webview',
        });
    },
    mounted(){
        let self = this;
        let isMiniApp = false;
        try{
            // eslint-disable-next-line no-undef
            if(my){
                isMiniApp = true
            }
        }catch{
            isMiniApp = false   
        }
        // isMiniApp = false
        if(isMiniApp){
            // eslint-disable-next-line no-undef
            my.onMessage = async function (e) {
                if(e.type == "FILE_UPLOADED"){
                    let statements = e.data.statements;
                    let newStatements = [];
                    statements.forEach(s => {
                        let startMonth = moment(s.start_date).format('MMM')
                        let endMonth = moment(s.end_date).format('MMM')
                        let statementMonth;
                        if(startMonth !== endMonth){
                            statementMonth = startMonth + '-' + endMonth
                        }else{
                            statementMonth = moment(s.start_date).format('MMMM')
                        }
                        newStatements.push({
                            filename: 'Statement - '+ (newStatements.length + 1),
                            verified:true,
                            month:statementMonth
                        })
                    });
                    
                    self.uploadedStatements = newStatements;
                    let isValid = e.data.bank_statements_valid == true && e.data.bank_statements_period >= 88;
                    if(isValid){
                        self.statementsCompleted = true;
                    }
                    self.statementLoading = false;
                }

                if(e.type == "ERROR_UPLOAD"){
                    try {
                        let error = e.error;
                        if(error.message){
                            self.statementError = error.message
                        }else{
                            self.statementError = 'There was an error while uploading your statement, please try again.'
                        }
                        self.statementLoading = false;
                    } catch (error) {
                        self.statementError = 'There was an error while uploading your statement, please try again.';
                        self.statementLoading = false
                    }
                    if(self.uploadedStatements.length == 0){
                        self.initialStep = true
                    }
                }
            }
        }
    },
    methods: {
        /**
         * Handle File Upload
         */
        async handleChange(e) {
            let fileList = e.target.files;
            this.handlingStatement = true
            this.uploadedStatements = this.uploadedStatements.filter((i)=> !i.error);

            let numStatements =  this.bankStatements.length;
            this.statementError = ''
            // let sError = ''
            this.bankStatements.push({
                filename: fileList[0].name,
                month: "",
                num: numStatements+1,
            });
            let newStatements = this.uploadedStatements            
            newStatements.push({
                filename:fileList[0].name,
                loading: true,
                month:'',
            });
            this.uploadedStatements = newStatements
            this.allowUpload = true
            this.fileList = fileList;
        },
        
        initiate(){
            this.statementError = '';
            this.statementLoading = true;
            this.initialStep = false;
            // eslint-disable-next-line no-undef
            my.postMessage({
                code:"INITIATE_UPLOAD",
                id:this.bankAccount.id,
            });
             window.utag.link({
                event_name: 'ui_interaction',
                page_name: 'vodapay: vodalend compare: bank statement manual upload',
                page_section: 'vodalend compare',
                page_channel: 'mobile app',
                visitor_login_status: 'logged in',
                page_type: 'webview',
                link_id: 'vodapay: vodalend compare: add files',
            });
        },


        async submitStatements(){
             window.utag.link({
                event_name: 'ui_interaction',
                page_name: 'vodapay: vodalend compare: bank statement manual upload',
                page_section: 'vodalend compare',
                page_channel: 'mobile app',
                visitor_login_status: 'logged in',
                page_type: 'webview',
                link_id: 'vodapay: vodalend compare: next',
            });
            try {
                this.loading = true;
                const { data } = await api.getSummary(this.bankAccount.id);
                this.summaryData = data
                let averages      = this.summaryData.averages;
                let monthsSummary = this.summaryData.months;
                let expenses      = averages.average_expenses;
                let grossIncome   = 0;
                let source        = '';
                let month         = '';
                if (Array.isArray(monthsSummary)) {
                    for (let i = 0; i < monthsSummary.length; i++) {
                        if (monthsSummary[i].main_income > 0 && monthsSummary[i].fullMonth && grossIncome === 0) {
                        grossIncome = monthsSummary[i].main_income;
                        source = monthsSummary[i].main_income_transaction;
                        month = monthsSummary[i].month;
                        }
                    }

                    if (grossIncome === 0) {
                        grossIncome = monthsSummary[0].incomes;
                        source = monthsSummary[0].main_income_transaction;
                        month = monthsSummary[0].month;
                    }
                }
                  let txData = {
                      income:grossIncome,
                      expenses:expenses,
                      income_source:source,
                      month:month
                  }
                  await this.$store.dispatch('application/updateTransactionData',txData)
                this.loading = false
                this.$router.push('select-income')
            } catch (error) {
                this.loading = false;
                this.$message('failed')
            }
 
        },
        retryUpload(){
            this.displayError = false
            this.error = ''
        },
        setFocus(val){
          this.$refs[val].$el.getElementsByTagName('input')[0].focus();
        },
        redirectToFaqs(){
            this.$store.dispatch('state/updateStatus', 'faqs-bank')
            this.$router.push('faqs/manual');
        },
        reportIssue(){
            this.$store.dispatch('state/updateStatus','report-issue')
            this.$router.push({ name: 'report-issue',params: { type:'online' } })
        },
        changeUploadMethod(){
            this.$store.dispatch('state/updateStatus','select-bank')
            this.$router.push({ name: 'select-bank'})
        },
    }
}
</script>
