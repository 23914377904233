<template>
    <div class="_spa-container" style="height: 100%;">
        <div style="height: 100%;display: flex;flex-direction: column;justify-content: space-between;">
             <div class="_container-curved"></div>
            <div class="_wording-container" style="margin-top:0px">
                <img src="/assets/spa/fail-envelop.svg"/>
                <span class="_title" style="margin-top: 20px;font-weight: 400;padding: 0px 20px;font-size: 28px;">There was an error!</span>
                <span class="_text" style="padding:20px 25px">Someone with a different ID number to you has applied using this email address. Please use a different email address to continue.</span>
            </div>
            <div class="_button-container" style="flex-direction:column">
                <primary-button id="primary_nav" style="width:100%;margin-left: 0px;" @click="redirect">Edit Details</primary-button>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    methods:{
        redirect(){
            this.$store.dispatch('state/updateStatus', 'personal-information')
            this.$router.push({name:'personal-information'})
        },
    },

}
</script>
