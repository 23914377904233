<template>
    <div v-if="step == 'display_animation'" >
        <img src="/assets/spa/SuccessAnimation.gif" style="width:100%"/>
    </div>
    <div v-else-if="step == 'verified'" class="_spa-container" style="padding-bottom: 20px;">
        <div></div>
        <div style="display: flex;justify-content: center;align-items: center;flex-direction: column;"> 
            <img src="/assets/spa/gift-side.svg" style="width: 250px;"/>
        </div>
        <div>
            <div class="_wording-container" v-if="type == 'sms'">
                <span class="_title" style="font-size: 26px;font-weight: 400;text-align: center;">Check for an SMS!</span>
                <span class="_text" style="padding: 20px;">Your application was successful, and you need to confirm using the link in the SMS.</span>
            </div>
            <div class="_wording-container" v-if="type == 'boodle'">
                <span class="_title" style="font-size: 26px;font-weight: 400;text-align: center;">Congratulations!!</span>
                <span class="_text" style="padding: 20px;" v-if="fraud_flag == 'yes'">Your Boodle Loan has been approved and is subject to a few final checks. Please expect an SMS within an hour on final outcome. </span>
                <span class="_text" style="padding: 20px;" v-else>Your Boodle loan has been approved. You will receive the money into your bank account in under 10 minutes.</span>
                <span class="_text">If you have any questions please call us on <strong>0861 266 353</strong> or email us at <strong>support@boodle.co.za,</strong> we’d be happy to help!</span>
            </div>
            
            <div style="display: flex;width: 100%;justify-content: space-between;">
                <primary-button id="primary_nav" type="button" style="width: 100%;justify-content: center;margin-left:0px"  @click="goHome()">Go To Home</primary-button>
            </div>
        </div>
        <el-dialog
            :visible.sync="dialogVisible"
            :modal-append-to-body="false"
            :append-to-body="true"
            :close-on-press-escape="false"
            :lock-scroll="true"
            :show-close="false"
            :close-on-click-modal="false"
            size="small"
            custom-class="_consent-dialog"
        >
            <img src="/assets/spa/lightbulb.svg"/>
            <span style="text-align:center">Please tap the exit icon on the top right of your screen to go to the VodaPay app.</span>
            <secondary-button id="secondary_nav" @click="dialogVisible = false" style="width:100%;color:#C9CCD4">Got It <img src="/assets/spa/icon-right.svg" style="height: 10px;width: auto;margin: 0px;margin-left: 10px;"/></secondary-button>
        </el-dialog> 
    </div>
    <div v-else-if="step == 're-routing'" class="_spa-container" style="justify-content:center">
        <div class="_wording-container" style="padding:50px 20px">
           <loading-spinner :width="100"/>
            <span class="_text">Rerouting. </span>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return {
            step:'display_animation',
            dialogVisible:false,
            type:this.$route.params.type,
            fraud_flag:this.$route.params.fraud_flag ? this.$route.params.fraud_flag : false
        }
    },
    mounted(){
        this.step = 'display_animation'
        setTimeout(() => {
            this.step = 'verified'
        }, 2200);
    },
    created() {
         window.utag.view({
            event_name: 'page_view',
            page_name: 'vodapay: vodalend compare: check for sms',
            page_section: 'vodalend compare',
            page_channel: 'mobile app',
            visitor_login_status: 'logged in',
            page_type: 'webview',
        });
    },
    methods: {
        goHome() {
            this.dialogVisible = true;
             window.utag.link({
                event_name: 'ui_interaction',
                page_name: 'vodapay: vodalend compare: check for sms',
                page_section: 'vodalend compare',
                page_channel: 'mobile app',
                visitor_login_status: 'logged in',
                page_type: 'webview',
                link_id: 'vodapay: vodalend compare: go to home',
            });
        }
    },
}
</script>