<template>
    <div  class="_spa-container _statement-content">
        <div>
            <div class="_wording-container">
                <span class="_title">Confirm Expense Details</span>
                <span class="_text">Please check that the below details which we retrieved from your statements are correct.</span>
            </div>
            <el-form :model="transactionForm" class="_input-form" :rules="rules" ref="expense-requirements">
                <fc-form-input
                    label="Average monthly expenses:"
                    prop="expenses"
                    :error="errors.expenses"
                    v-model="transactionForm.expenses"
                    :currency="true"
                    inputmode="numeric"
                    type="tel"
                    pattern="[0-9]*"
                    name="expenses"
                ></fc-form-input>
            </el-form>
        </div>
        <div>
            <div style="display: flex;width: 100%;justify-content: space-between;flex-direction:column">
                <primary-button id="primary_nav" type="button" style="width:100%; margin-left:0px;margin-bottom:10px;" @click="submitSummaryData">Complete Verification</primary-button>
                <secondary-button id="secondary_nav" type="button" style="width:100%;margin-left:0px; " @click="cancel" >Cancel</secondary-button>
            </div>
        </div>
        <el-dialog
            :visible.sync="cancelPopup"
            :modal-append-to-body="false"
            :append-to-body="true"
            :close-on-press-escape="false"
            :lock-scroll="true"
            :show-close="false"
            :close-on-click-modal="false"
            size="small"
            custom-class="_consent-dialog"
        >
            <img src="/assets/spa/lightbulb.svg"/>
            <span style="text-align:center;font-size:16px;font-weight: 600;">Are you sure?</span>
            <span style="text-align:center;font-size: 14px;font-weight: 400;">Are you sure you want to cancel your application?</span>
            <span style="text-align:center;font-size: 14px;font-weight: 400;">If you’re sure you want to cancel, tap the exit icon on the top right of your screen to go to the VodaPay app.</span>
            <secondary-button id="secondary_nav" @click="cancelPopup = false" style="width:100%;font-size:16px"> Got it<img src="/assets/spa/icon-right.svg" style="height: 10px;width: auto;margin: 0px;margin-left: 10px;"/></secondary-button>
        </el-dialog> 
    </div>
</template>


<script>
import {mapGetters, mapState} from "vuex";
import { mapFields, validateAmount } from '../helpers.js' 
import api from '../api'

export default {
    data(){
        return {
            selectedTransaction:'',
            edittingIncome: false,
            errors:{},
            cancelPopup:false,
            //rules
            rules: {
                expenses: [
                    { required: true, validator: validateAmount, trigger: "blur" },
                ]
            },
        }
    },
    computed: {
        ...mapState({
            transactionForm: state => state.application.transactionData,
        }),
        ...mapGetters('application', {
            bankAccount: 'bankAccount'
        }),
        ...mapFields({
            fields: ['expenses'],
            store: 'application',
            fieldMap: 'transactionData',
            target: 'transactionForm',
            mutation: 'updateTransactionDataField'
        }),
    },
    created() {
         window.utag.view({
            event_name: 'page_view',
            page_name: 'vodapay: vodalend compare: confirm expense details',
            page_section: 'vodalend compare',
            page_channel: 'mobile app',
            visitor_login_status: 'logged in',
            page_type: 'webview',
        });
    },
    methods:{
        cancel(){
             window.utag.link({
                event_name: 'ui_interaction',
                page_name: 'vodapay: vodalend compare: confirm expense details',
                page_section: 'vodalend compare',
                page_channel: 'mobile app',
                visitor_login_status: 'logged in',
                page_type: 'webview',
                link_id: 'vodapay: vodalend compare: cancel',
            });
            this.cancelPopup = true
        },

        async submitSummaryData(){
            window.utag.link({
                event_name: 'ui_interaction',
                page_name: 'vodapay: vodalend compare: confirm expense details',
                page_section: 'vodalend compare',
                page_channel: 'mobile app',
                visitor_login_status: 'logged in',
                page_type: 'webview',
                link_id: 'vodapay: vodalend compare: complete verification',
            });
            this.$refs['expense-requirements'].validate(async valid => {
                if(valid){
                    this.loading = true,
                    await api.submitSummaryData(this.bankAccount.id,this.transactionForm);
                    await this.$store.dispatch('application/updatePersonalLoanField',{
                        key:'net_income',
                        value: this.transactionForm.income
                    })
                    await this.$store.dispatch('application/updatePersonalLoanField',{
                        key:'expenses',
                        value: this.transactionForm.expenses
                    })
                    await this.$store.dispatch('application/updatePersonalLoanField',{
                        key:'income_source',
                        value: this.transactionForm.income_source
                    })
                    this.loading = false;
                    await this.$store.dispatch('state/updateStatus', 'income-confirmed')
                    await this.$router.push({ name: 'income-confirmed' })
                }
            })
        },

    }
}
</script>