<template>
    <div class="_spa-container">
        <div class="_statement-content">
            <div>
                <div class="_wording-container">
                    <span class="_title">Let’s get to know you</span>
                    <span class="_text">Please fill in your personal details.</span>
                </div>
                <el-form :model="personalForm" class="_personal-form" :rules="rules" ref="requirements" >
                    <fc-form-input
                        label="First Name"
                        prop="first_name"
                        :error="errors.first_name"
                        v-model="personalForm.first_name"
                        placeholder="John"
                        name="first_name"
                    ></fc-form-input>
                    <fc-form-input
                        label="Middle Name(s)"
                        prop="middle_name"
                        :error="errors.middle_name"
                        v-model="personalForm.middle_name"
                        placeholder="Matthew"
                        name="middle_name"
                    ></fc-form-input>
                    <fc-form-input
                        label="Last Name"
                        prop="last_name"
                        :error="errors.last_name"
                        v-model="personalForm.last_name"
                        placeholder="Doe"
                        name="last_name"
                    ></fc-form-input>
                    <fc-form-input
                        label="ID Number"
                        prop="id_number"
                        :error="errors.id_number"
                        v-model="personalForm.id_number"
                        placeholder="9506115197085"
                        name="id_number"
                    ></fc-form-input>
                    <fc-form-input
                        label="Mobile Number"
                        prop="cell_phone_number"
                        :error="errors.cell_phone_number"
                        v-model="personalForm.cell_phone_number"
                        placeholder="0813236689"
                        inputmode="numeric"
                        type="tel"
                        pattern="[0-9]*"
                        name="cell_phone_number"
                    ></fc-form-input>
                    <fc-form-input
                        label="Email"
                        prop="email"
                        :error="errors.email"
                        v-model="personalForm.email"
                        placeholder="john.doe@finch.co.za"
                        name="email"
                        :maxlength="100"
                    ></fc-form-input>
                </el-form>
                <div class="_secured-container" style="margin-top:50px">
                    <div class="secured-spacer">
                        <div class="_divider"></div>
                        <img src="/assets/spa/secured.svg"/>
                        <div class="_divider"></div>
                    </div>
                    <span>We employ the best security measures when verifying your identity. We can assure you you’re in safe hands.</span>
                </div>
            </div>
            <div style="margin-top:30px;margin-bottom:350px">
                <div style="display: flex;width: 100%;justify-content: space-between;">
                    <primary-button id="primary_nav" type="button" style="width: 100%;justify-content: center;" @click="submitPersonalInformation" :disabled="!personalForm.first_name || !personalForm.last_name || !personalForm.id_number || !personalForm.cell_phone_number || !personalForm.email">
                        <button-loading-spinner v-if="loading"/>
                        <span v-else>Next</span> 
                    </primary-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { mapFields, validatePhone, validateId } from '../helpers.js' 
export default {
    mounted(){
        window.scrollTo(0,0)
    },
    data(){
  
        return {
            errors:{
                email:''
            },
            loading:false,
            rules: {
                first_name: [
                {
                    required: true,
                    message: "Please input your first name",
                    trigger: "blur",
                },
                ],
                last_name: [
                {
                    required: true,
                    message: "Please input your last name",
                    trigger: "blur",
                },
                ],
                cell_phone_number: [
                { required: true, validator: validatePhone, trigger: "blur" },
                {
                    required: true,
                    message: 'Please input your cell phone number',
                    trigger: 'blur',
                },
                ],
                email: [
                {
                    required: true,
                    message: "Please input your email",
                    trigger: "blur",
                },
                {
                    type: "email",
                    message: "Please input a valid email address",
                    trigger: "change",
                },
                ],
                id_number: [
                    { required: true, validator: validateId, trigger: "blur" },
                ],
            },
        }
    },
    computed:{
        ...mapState({
            personalForm: state => state.application.personalLoan,
        }),
        ...mapFields({
            fields: ['first_name','middle_name','last_name','id_number','cell_phone_number','email'],
            store: 'application',
            fieldMap: 'personalLoan',
            target: 'personalForm',
            mutation: 'updatePersonalLoanField'
        }),
        ...mapGetters('application', {
            type: 'type',
        })
    },
     created() {
        window.utag.view({
            event_name: 'page_view, form_start',
            page_name: 'vodapay: vodalend compare: personal details',
            page_section: 'vodalend compare',
            page_channel: 'mobile app',
            visitor_login_status: 'logged in',
            page_type: 'webview',
            form_name: 'personal details'
        });
    },
    methods:{
        async submitPersonalInformation(){
            this.$refs['requirements'].validate(async valid => {
                this.loading = true;
                if(valid){
                    let res = {}
                    res = await this.$store.dispatch('application/createUser');
                    if(res.error){
                        let errors = {};
                        errors[res.type] = res.message;
                        this.errors[res.type] = res.message
                        this.loading = false;
                    }
                    window.utag.link({
                        event_name: 'ui_interaction, form_end',
                        page_name: 'vodapay: vodalend compare: personal details',
                        page_section: 'vodalend compare',
                        page_channel: 'mobile app',
                        visitor_login_status: 'logged in',
                        page_type: 'webview',
                        link_id: 'vodapay: vodalend compare: next',
                        form_name: 'personal details'
                    });
                    if(this.type == 'get-quote'){
                        await this.$store.dispatch('state/updateStatus', 'consent')
                        this.$router.push({name:'consent'})
                    }else{
                        await this.$store.dispatch('state/updateStatus', 'select-bank')
                        this.$router.push({name:'select-bank'})
                    }
                }else{
                    window.scrollTo(0, 0);
                    return false;
                }
            })
            this.loading = false;
        }
    }
}
</script>