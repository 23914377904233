<template>
    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M55.9687 55.6333C55.9687 57.6308 54.4667 59.25 52.6138 59.25H12.3554C10.5025 59.25 9.00049 57.6308 9.00049 55.6333V8.61667C9.00049 6.61924 10.5025 5 12.3554 5H41.0047C41.8805 4.99947 42.7217 5.36809 43.3487 6.02713L54.9556 16.9784C55.6033 17.659 55.9686 18.5928 55.9687 19.5679V55.6333Z" :stroke="isActive ? '#000' : '#F2F4F7'" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M19.1663 23.0835H46.0052" :stroke="isActive ? '#000' : '#F2F4F7'" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M19.1663 33.9336H46.0052" :stroke="isActive ? '#000' : '#F2F4F7'" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M19.1663 44.7832H32.5858" :stroke="isActive ? '#000' : '#F2F4F7'" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>


</template>

<script>
export default {
    props:['isActive']
}
</script>