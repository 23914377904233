<template>
    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M52.5333 22.6667V7.73333C52.5333 5.67147 50.8619 4 48.8 4H7.73333C5.67147 4 4 5.67147 4 7.73333V37.6C4 39.6619 5.67147 41.3333 7.73333 41.3333H33.8667" :stroke="isActive ? '#000' : '#F2F4F7'" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M4 15.2002H52.5333" :stroke="isActive ? '#000' : '#F2F4F7'" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M11.4668 24.5337H26.4001" :stroke="isActive ? '#000' : '#F2F4F7'" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M11.4668 32H18.9335" :stroke="isActive ? '#000' : '#F2F4F7'" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M41.3333 31.6333C41.3333 30.8049 42.0048 30.1333 42.8333 30.1333H58.4999C59.3283 30.1333 59.9999 30.8049 59.9999 31.6333V58.5C59.9999 59.3284 59.3283 60 58.4999 60H42.8333C42.0048 60 41.3333 59.3284 41.3333 58.5V31.6333Z" :stroke="isActive ? '#000' : '#F2F4F7'" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M41.3333 52.5337H59.9999" :stroke="isActive ? '#000' : '#F2F4F7'" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>

</template>

<script>
export default {
    props:['isActive']
}
</script>