<template>
  <div class="_spa-container" style="justify-content: center;">
    <div class="_loading-screens">
      <loading-spinner :width="100" />
      <div class="_wording-container">
        <span class="_text" style="padding:20px 25px">
        Get Excited! We're taking you to a page where you can sign and accept your offer.
        </span>
        <span class="_text" style="padding:20px 25px">
        Once you have completed your loan journey with your chosen lender please close the Vodapay app on the top right of your screen.
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      start_time: null,
      isMiniApp: false,
      apiUrl: process.env.VUE_APP_ENGINE_URL,
      apiKey: process.env.VUE_APP_ENGINE_KEY,
    };
  },
  computed: {
    ...mapGetters({
      offers: "offers/offers",
      selectedOffer: "offers/selectedOffer",
    }),
  },
  created() {
    this.startTime = new Date().getTime();
  },
  mounted() {
    this.processRedirectLink();
    let isMiniApp = false;
    try {
      // eslint-disable-next-line no-undef
      if (my) {
        isMiniApp = true;
      }
    } catch {
      isMiniApp = false;
    }
    // isMiniApp = false
    if (isMiniApp) {
      this.message = "miniappset";
      // eslint-disable-next-line no-undef
      my.onMessage = async function(e) {
        this.message = e;
        if (e.type == "REDIRECT_SUCCESS") {
          this.handleSuccessFallBack();
        }
        if (e.type === "REDIRECT_FAILED") {
          this.router.push({ name: "redirect-update" });
        }
      };
    }
    this.isMiniApp = isMiniApp;
  },

  methods: {
    async processRedirectLink() {
      try {
        const url = this.apiUrl + `/api/v1/accept/${this.offers.id}/link`;
        const { data } = await axios.get(url, {
          headers: { Authorization: "Bearer " + this.apiKey },
        });

        if (data.ready) {
          this.handleRedirect(data.link);
          return;
        }

        if (new Date().getTime() - this.startTime < 60000) {
          setTimeout(this.processRedirectLink, 3000);
          return;
        } else {
          this.handleSuccessFallBack();
        }
        //TODO: Handle error
      } catch (error) {
        this.$router.push({ name: "technical-error" });
        console.log(error);
      }
    },

    handleRedirect(link) {
      console.log(`handle -> ${link}`);
      if (this.isMiniApp) {
        // eslint-disable-next-line no-undef
        my.postMessage({
          code: "REDIRECT_URL",
          url: link,
        });
      } else {
        window.open(link, "_blank");
      }
    },

    handleSuccessFallBack() {
      let companyName = this.selectedOffer.company_name;
      let fraudFlag = "no";
      if (
        this.selectedOffer.live_score &&
        this.selectedOffer.live_score.HasFraudFlag
      ) {
        fraudFlag = "yes";
      }
      if (companyName == "Boodle") {
        this.$router.push({
          name: "success",
          params: {
            type: companyName.toLocaleLowerCase(),
            fraud_flag: fraudFlag,
          },
        });
      } else {
        this.$router.push({ name: "success", params: { type: "sms" } });
      }
    },
  },
};
</script>
<style lang="scss">
._loading-screens {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  p {
    color: white !important;
  }
}
</style>
