<template>
    <div class="_spa-container" style="height: 100%;">
         <div style="height: 100%;display: flex;flex-direction: column;justify-content: space-between;">
             <div class="_container-curved"></div>
            <div class="_wording-container" style="margin-top:0px">
                <img src="/assets/spa/error-ice-cream.svg" style="max-width: 300px;"/>
                <span class="_title" style="margin-top: 20px;font-weight: 400;padding: 0px 20px;font-size: 28px;">We’re sorry!</span>
                <span class="_text" style="padding:20px 25px">There was a technical error on our side.</span>
            </div>
            <div class="_button-container" style="flex-direction:column">
                <primary-button id="primary_nav" style="width:100%;margin-left: 0px;" @click="goBack">Try Again</primary-button>
                <secondary-button id="secondary_nav" style="width: 100%;justify-content: center;margin-left: 0px;margin-top: 10px;" @click="reportIssue">Report Issue</secondary-button>
            </div>
            </div>
    </div>
</template>

<script>

export default {
    methods:{
        goBack(){
            this.$router.go(-1)
        },
        reportIssue(){
            this.$router.push({name:'report'})
        }
    },

}
</script>
