import api from '../../api'
// initial state
const state = () => ({
    status: 'start',
    previous:'',
    banks:[],
})

const getters = {
    status: (state) => state.status,
    previous:(state)=>state.previous,
    banks:(state)=>state.banks,
}

const actions = {
    updateStatus ({ commit}, status) {
        commit('updateStatus', status, )
    },
    setIsLoggedIn ({ commit}, status) {
        commit('setIsLoggedIn', status, )
    },
    async initialState({commit}){
        const { data } = await api.getBanks()
        commit('updateBanks', data);
    }
}

const mutations = {
    updateStatus(state, status) {
        state.previous = state.status
        state.status = status
    },
    updateBanks(state, banks){
        state.banks = banks
    },
    setIsLoggedIn(state, loggedIn){
        state.isLoggedIn = loggedIn
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}

