export function mapFields(options) {
    const object = {};
    for (let x = 0; x < options.fields.length; x++) {
        const field = [options.fields[x]];
        object[field] = {
            get() {
                return this.$store.state[options.store][options.fieldMap][field];
            },
            set(value) {
                this.$store.commit(`${options.store}/${options.mutation}`, { key: field, value });
                this[options.target][field] = value
            }
        };
    }
    return object;
}


/**
* Verify Control Digit of ID number
* @param  idNumber
* @return
*/
export function controlDigitValidate(idNumber) {
    let checkDigit = parseInt(idNumber[12], 10);
 
    let oddDigitsSum = 0;
 
    for (let i = 0; i < idNumber.length - 1; i += 2) {
        oddDigitsSum += parseInt(idNumber[i], 10);
    }
    let evenDigits = "";
    for (let j = 1; j < idNumber.length - 1; j += 2) {
        evenDigits += idNumber[j];
    }
    evenDigits = parseInt(evenDigits, 10);
    evenDigits *= 2;
    evenDigits = "" + evenDigits;
 
    let sumOfEvenDigits = 0;
    for (let k = 0; k < evenDigits.length; k++) {
        sumOfEvenDigits += parseInt(evenDigits[k], 10);
    }
    let total = sumOfEvenDigits + oddDigitsSum;
    let computedCheckDigit = 10 - (total % 10);
 
    if (computedCheckDigit === 10) {
        computedCheckDigit = 0;
    }
    return computedCheckDigit === checkDigit;
 }
 
export function validateEmploymentYear (rule, value, callback) {
    let ex = /^[0-9]\d*(\.\d+)?$/;
    let year = new Date().getFullYear();
    if (typeof value === 'string' || value instanceof String){
        value = value.replace(/\s/g, '');
    }
    if (ex.test(value) === false) {
        callback(new Error("Please enter a valid amount (Numbers Only)"));
    } else if (value < 1980) {
        callback(new Error("Please input a value greater than 1980"));
    } else if (value > year) {
        callback(new Error("Please input a value less than " + year));
    } else {
        callback();
    }
}

export function validatePhone (rule, value, callback) {
    if (value[0] !== "0") {
        callback(new Error("Please enter a valid phone number"));
        return;
    }

    if ( value.length !== 10 ) {
        callback(new Error("Please input a valid 10-digit phone number"));
    } else {
        callback();
    }
}

export function validateId (rule, value, callback) {
    let ex = /^[0-9]{13}$/;

    if (value == "1111111111111") {
        callback();
        return;
    }

    if ( ex.test(value) === false || controlDigitValidate(value) === false
    ) {
        callback(new Error("Please enter a valid RSA ID number"));
    } else {
        callback();
    }
}

export function validateAmount (rule, value, callback) {
    let ex = /^[0-9]\d*(\.\d+)?$/;

    if (typeof value === 'string' || value instanceof String){
        value = value.replace(/\s/g, '');
    }  
    if (ex.test(value) === false) {
        callback(new Error("Please enter a valid amount (Numbers Only)"));
    } else {
        callback();
    }
}

export function orderedPartners (partners, type) {
    let newPartners = [];
    for (let index = 0; index < partners.length; index++) {
      const partner = partners[index];
      if (type == "apply-now" && partner.end_to_end && partner.live_score) {
        if (
          partner.live_score &&
          partner.live_score.offers &&
          partner.live_score.offers.length > 0
        ) {
          let offers = partner.live_score.offers;
          offers.forEach((offer) => {
            newPartners.push({...offer, end_to_end:true});
          });
        } else {
          newPartners.push(partner);
        }
      } else if (type == "get-quote") {
        if (
          partner.live_score &&
          partner.live_score.offers &&
          partner.live_score.offers.length > 0
        ) {
          let offers = partner.live_score.offers;
          offers.forEach((offer) => {
            newPartners.push({...offer, end_to_end:false});
          });
        } else {
          newPartners.push({...partner, end_to_end: false});
        }
      }
    }
    console.log(type)
    console.log(newPartners.length);
    let partnerCount = newPartners.filter(
        function(offer) {
          return offer.live_score != -2;
        }
      ).length
    if (partnerCount == 0 && type == "apply-now"){
        console.log('hello world')
        for (let index = 0; index < partners.length; index++) {
            const partner = partners[index];
            if (
                partner.live_score &&
                partner.live_score.offers &&
                partner.live_score.offers.length > 0
              ) {
                let offers = partner.live_score.offers;
                offers.forEach((offer) => {
                  newPartners.push({...offer, end_to_end:false});
                });
              } else {
                newPartners.push({...partner, end_to_end:false});
              }
        }
    }
    return newPartners;
    
}