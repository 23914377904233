import styled from 'vue-styled-components'

//TODO - Update button to align with advanced button styling
const StyledContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex: 1;
    flex-basis: auto;
    box-sizing: border-box;
    background: #08081B;
    color:white;
    min-height: calc(100vh - 14px);
`;

export {
    StyledContainer,
}