<template>
    <div v-if="step == 'display_animation'" >
        <img src="/assets/spa/SuccessAnimation.gif" style="width:100%"/>
    </div>
    <div v-else-if="step == 'verified'" class="_spa-container">
        <div class="_wording-container" style="padding:50px 20px">
           <img src="/assets/spa/round-success.svg"/>
            <span class="_title" style="padding: 0px 34px;padding-top: 10px;">Bank statements verified!</span>
            <span class="_text">Your bank statements have been verified. You are one step away from being able to view personalised loan offers from your best suited lenders.</span>
        </div>
        <div>
            <div style="display: flex;width: 100%;justify-content: space-between;">
                <primary-button id="primary_nav" type="button" style="width: 100%;justify-content: center;margin-left:0px"  @click="goToConsent">Next</primary-button>
            </div>
        </div>
    </div>
    <div v-else-if="step == 're-routing'" class="_spa-container" style="justify-content:center">
        <div class="_wording-container" style="padding:50px 20px">
           <loading-spinner :width="100"/>
            <span class="_text">Rerouting. This won’t take long.</span>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return {
            step:null
        }
    },
    mounted(){
        this.step = 'display_animation'
        setTimeout(() => {
            this.step = 'verified'
        }, 2200);
    },
    created() {
         window.utag.view({
            event_name: 'page_view',
            page_name: 'vodapay: vodalend compare:bank statements verified',
            page_section: 'vodalend compare',
            page_channel: 'mobile app',
            visitor_login_status: 'logged in',
            page_type: 'webview',
        });
    },
    methods:{
        goToConsent(){
            window.utag.link({
                event_name: 'ui_interaction',
                page_name: 'vodapay: vodalend compare:bank statements verified',
                page_section: 'vodalend compare',
                page_channel: 'mobile app',
                visitor_login_status: 'logged in',
                page_type: 'webview',
                link_id: 'vodapay: vodalend compare: next',
            });
            this.step = 're-routing'
            setTimeout(() => {
                window.utag.view({
                    event_name: 'page_view',
                    page_name: 'vodapay: vodalend compare:bank statements verified loading',
                    page_section: 'vodalend compare',
                    page_channel: 'mobile app',
                    visitor_login_status: 'logged in',
                    page_type: 'webview',
                });
                this.$router.push({name:'consent'})
            }, 4000);
        }
    }
}
</script>