<template>
    <boodle/>
</template>

<script>
import Boodle from './TermsAndConditions/Boodle.vue'
export default {
    components:{
        'boodle':Boodle
    },
    data(){
        return {
            slug: this.$route.params.partner
        }
    }
}
</script>