<template>
    <div class="_spa-container" style="min-height: 100%;display: flex;flex-direction: column;justify-content: space-between;">
        <div class="_spa-home">
            <div class="_wording-container" style="margin-top: 0px;">  
                <span class="_title">FAQ’s</span>
                <span class="_text">These are some commonly asked questions about the bank statement collection process.</span>
            </div>
            <div class="_faqs-section">
                <el-collapse v-model="activeQuestions">
                    <el-collapse-item v-for="(item,index) in computedFaqs" :title="item.question" v-bind:key="index" :name="index">
                        <div style="display: flex;flex-direction: column;">
                            <template v-if="!item.list"> 
                                <template v-if="Array.isArray(item.answer)">
                                    <span v-for="(answerItem,index) in item.answer" style="margin-top: 10px;" v-bind:key="'item--'+index"> {{answerItem}}</span>
                                </template>
                                <span v-else-if="item.link" v-html="convertToHtml(item)"></span>
                                <span v-else>
                                    {{ item.answer }}
                                </span>
                            </template>
                            <template v-else>
                                <span v-if="item.beforeList">{{item.beforeList}}</span>
                                <ol v-if="item.listItems.length > 0">
                                    <li v-for="(i,index) in item.listItems" v-bind:key="'list--'+index">{{i}}</li>
                                </ol> 
                                <template v-if="Array.isArray(item.afterList)">
                                    <span v-for="(i,index) in item.afterList" style="margin-top: 10px;" v-bind:key="'after-list--'+index"> {{i}}</span>
                                </template>
                                <span v-else>
                                    {{item.afterList}}
                                </span>
                                <ul v-if="item.secondList">
                                    <li v-for="i in item.secondList" v-bind:key="'list--'+i">{{i}}</li>
                                </ul>
                            </template>

                        </div>
                    </el-collapse-item>
                    
                </el-collapse>
            </div>

        </div>
            <div class="_button-container">
                <secondary-button id="secondary_nav" @click="goBack" style="width: 100%;justify-content: center;margin-top:20px">Go Back</secondary-button>
            </div>
    </div>
</template>
<script>
import {mapGetters} from "vuex";

export default {
    computed: {
        ...mapGetters('state', {
            status: 'status',
            previous:'previous'
        }),
        computedFaqs(){
            if(this.type == 'manual'){
                return [...this.faqs.manual , ...this.faqs.online];
            }else{
                return this.faqs[this.type]
            }
        }
    },
    created() {
        window.utag.view({
            event_name: 'page_view',
            page_name: 'vodapay: vodalend compare: faqs',
            page_section: 'vodalend compare',
            page_channel: 'mobile app',
            visitor_login_status: 'logged in',
            page_type: 'webview',
        });
    },

    data(){
        return {
            activeQuestions:[],
            type:this.$route.params.type,
            faqs:{
                online:[
                    {
                        question:'What options are available to provide my bank statements?',
                        answer: [
                            'Currently, we have two options available. The first one, the online option requires you to provide your online banking credentials. This is both the most secure option as well as the fastest way for us to assess and approve your loan.',
                            'If you don’t have internet banking, you can upload your most recent 3 months bank statements manually, in a pdf format, following the easy to understand steps in the Vodalend Compare mini-app.'
                        ]
                    },
                    {
                        question:'How do I know the online login process is secure?',
                        answer:'The highest possible level of encryption is used. No person can view your login details. '
                    },
                    {
                        question:'What is the online login process?',
                        answer:'When you enter your bank details, your details are immediately encrypted using a unique algorithm. We then use an automated technology that logs into your online profile, and searches for your associated bank accounts. We only read the figures and transactions in your account, and in the same way that you would export a PDF, we download the transaction descriptions which are in your transactional history. Once we’re done, we log out securely and re-encrypt your bank details for extra safety. The good news is, when we say “we” it actually means our technology, so your information is both secure and private from any human eyes.'
                    },
                    {
                        question:'Why apply here?',
                        answer:'While shopping around for the best deal takes time and can cost you more both in terms of time and money, we gather all your information once, at no cost to you and provide you with the best offer from our partners in minutes without you needing to visit a branch or talk to a call centre. Immediate, easy and trusted.'
                    },
                    {
                        question:'Do you store bank account login information forever?',
                        answer:'No. We only use it for the time necessary to process your application. We use your details for the time needed, and then remove them all together.'
                    },
                    {
                        question:'Who has access to my bank account login information?',
                        answer: 'No one will have access to, or be able to see your bank account login details. All information is immediately encrypted as you enter it into our system.'
                    },
                    {
                        question:'Can employees view my bank transaction information?',
                        answer:[
                            'No, employees do not have access to your bank transaction information. Your bank transaction information is only shared with the intended recipient, in this case being the financial service provider you are enquiring with.',
                            'If there is an issue with your application, an employee may request access to view your files to check the cause of the error. This will only be done on your approval.',

                        ]
                    },
                    {
                        question:'What encryption standards are used to keep my information secure?',
                        answer:'We use Hardware Security Modules (HSM’s) which are graded to the FIPS 140-2 Standard. This is a US-government computer security standard for processing information. Your information is never actually written to a physical server, and rather stays in dynamic memory which is immediately deleted when it is no longer necessary.'
                    },
                    {
                        question:'How do I use the automatic login process?',
                        answer:'First, you login through our secure and encrypted portal as you would with your online banking portal. Our technology then fetches your bank statements automatically and downloads the required information to our secure server for processing.'
                    },
                    {
                        question:'Why are you asking for my login details? It seems very risky.',
                        answer:'In order to automatically download your statements, we need “Read-Only” access to be able to log in to your bank account on our behalf and then download your statements. This is a common procedure that is used to read information from a secure, read-only, accessibility.'
                    },
                    {
                        question:'Why do I get notifications from my bank when I use this service?',
                        answer:'Your bank notifies you whenever someone logs into your account. Because we are doing that on your behalf, you will likely get a notification to say that someone has logged in. This is normal and can be expected every time you use this service.'
                    },
                    {
                        question:'What happens if I put in the wrong login details?',
                        answer:'The same process as your online banking portal applies. If you put in the wrong details more than three times, your account will likely be temporarily closed by your bank until you reset the password. You will need to do this through your banking app or online banking portal.'
                    },
                    {
                        question:'Why does the system reject my PDF Bank Statement?',
                        list:true,
                        beforeList:'There are a few reasons that your bank statement might be rejected:', 
                        listItems:[
                            'It does not have the layout/format of a normal bank statement.',
                            'It is not actually a bank statement. We don’t accept loan or credit account documents.',
                            'The bank statement has been altered. We have technology that recognizes if the bank statement is in its original, untampered format.'
                        ],
                        afterList:['If you feel that your bank statement shouldn’t be failing for any of these reasons, please feel free to report the error.']
                    },
                    
                ],
                manual:[
                    {
                        question:'What kind of documents can I upload?',
                        answer:'At this stage you can only upload a pdf format version of your actual bank statement that can be downloaded from your internet banking or requested at a branch. The document has to be saved on your device. Unfortunately, you cannot upload transaction summaries or statements generated at the ATM.'
                    },
                    {
                        question:'What do I need to do to be able to upload documents?',
                        answer:'You need to ensure that your permissions on the Vodapay app allows the app to access documents stored on your device. This process differs from device to device, but access will need to be enabled to complete the process. You will be able to disable the access again once your application is complete.'
                    }
                ],
                credit:[
                    {
                        question:'What is the reason for no offers being shown',
                        answer:[
                            'We send your application and affordability details to our partner banks and lenders. Our partners run an affordability check on your details. Based on their risk appetite, they will go ahead and offer finance to you or they may decide that they are unable to assist. In the event that our partners are unable to assist you – no offers will be presented.',
                            'This could be due to no credit record, impaired credit record, not enough financial information on you yet, age or affordability – to name a couple reasons'
                        ]
                    },
                    {
                        question:'Are our lenders safe and reputable?',
                        answer: 'We work very closely with the lenders we partner with. They are all registered credit providers, trustworthy, and have their details fully accessible on the NCR.'
                    }, 
                    {
                        question:'Which lenders am I able to get a loan from?',
                        list:true,
                        beforeList:'The following is the list of lenders you could currently get a loan from through Vodalend Compare: ',
                        listItems:[
                            'Finchoice',
                            'Nedbank',
                            'Mpowa',
                            'African Bank',
                            'FASTA',
                            'Lime',
                            'Boodle',
                            'Uloans',
                            'Unifi'
                        ],
                        afterList:'We are continuously working towards adding more lenders.',
                    },
                    {
                        question:'Do we promote or “push” specific companies?',
                        answer:[
                            'No, we will never promote a company because of our position as an independent marketplace. We present updated information on all the partners we work with and leave the decision 100% up to you.',
                            'We only aim to make the latest information and deals available to you to ensure that you are able access and compare the best offers.'
                        ]
                    },
                    {
                        question:'Why apply here?',
                        answer:'While shopping around for the best deal takes time and can cost you more both in terms of time and money, we gather all your information once, at no cost to you and provide you with the best offer from our partners in minutes without you needing to visit a branch or talk to a call centre. Immediate, easy and trusted.'
                    },
                    {
                        question:'How much will a lender charge me?',
                        list:true,
                        beforeList:'There are generally 3 charges that a lender will add to your loan agreement:', 
                        listItems:[
                            'Initiation fee',
                            'Service fee',
                            'Interest rate'
                        ],
                        afterList:['The actual amount of money each of these charges will cost you depends greatly on which lender you decide to go with.']
                    },
                    {
                        question:'What do I need to apply?',
                        list:true,
                        beforeList:'The basic documents you will need for any application are copies of your:', 
                        listItems:[
                            'ID',
                            'Proof of residence',
                            'Latest payslip',
                            'Last 3 months bank statements'
                        ],
                        afterList:['Important: We don’t require any of these documents from you for our use. These are for the partner you select and who will finalise your agreement.']
                    },
                    {
                        question:'What if I am Blacklisted?',
                        answer:[
                            'Fortunately, our engine is all about helping you make better financial decisions!',
                            'The product offers you get matched with are based on the details you provide us. If these details are accurate and not falsified, you should have the right product offers in front of you!',
                            'It is, however, important for you to know what financial product you are agreeing to. If you are uncertain about anything related to this process, it is important to read up and research the products, companies, and make sure you understand how this process works.'
                        ]
                    },
                    {
                        question:'What does it cost?',
                        answer:'This process is completely free. The only cost to you will be with the partner offer you select and complete your application with. In this case, the costs will be related to the admin of processing your agreement with the financial services provider.'
                    },
                    {
                        question:'Can I click on more than offer?',
                        answer:[
                            'In some cases, you might be able to click on more than one offer, especially if you are doing an application for the second time.',
                            'We would, however, suggest sticking with one partner offer at a time as it will help you to get the healthiest results when it comes to making a better decision. This will also protect your credit score from taking a knock if you have been applying all over the internet and other financial partners are processing your applications.'
                        ]
                    }
                ]
            }
        }
    },
    watch:{
        'type': {
            handler (status) {
                console.log(status)
            },
            immediate: true // make this watch function is called when component created
        }
    },
    methods:{
        goBack(){
            this.$router.go(-1)
        },
    }
}
</script>
<style lang="scss">
._faqs-section{
    margin-top: 15px;
}
.el-collapse-item__header{
    background: #222739;
    border-radius: 8px;
    color: white;
    border-bottom:none !important;
    font-family: 'Roboto';
    line-height: initial !important;
}
.el-collapse-item__header.is-active{
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
}
.el-collapse-item__content{
    padding-bottom: 5px;
    div{
        background: #222739;
        border-radius: 8px;
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
        padding: 10px;
        padding-top: 0;
        color: #A8ACB8;
    }
}
</style>