<template>
    <div class="_spa-container">
        <div>
            <div style="height: -webkit-fill-available;">
                <div class="_wording-container">
                    <span class="_title">Let’s start... How much do you need?</span>
                </div>
                <el-form :model="personalForm" ref="requirements" :rules="rules" @submit.native.prevent="preventSubmit">
                    <fc-form-input
                        label="How much would you like to borrow?"
                        prop="loan_amount_required"
                        :error="errors.loan_amount_required"
                        v-model="personalForm.loan_amount_required"
                        :currency="true"
                        inputmode="numeric"
                        type="tel"
                        pattern="[0-9]*"
                        placeholder="10000.00"
                        :min="500"
                        :max="350000"
                        name="loan_amount_required"
                    ></fc-form-input>
                <div class="_bank-radios" style="margin-top:0px">
                    <span style="color: #A8ACB8 !important;font-size: 16px !important;margin-top: 10px;display: block;margin-bottom: 10px;">How long do you need it for?</span>
                    <el-radio-group v-model="personalForm.repayment_period">
                        <el-radio-button 
                            v-for="item in repayment_periods"
                            id="selector"
                            :selection="toKebab(item.label)"
                            :label="item.value"
                            :key="item.value"
                            :value="item.value"
                            style="margin-bottom: 0px;"
                        >
                        <img src="/assets/spa/dark-calendar.svg" style="width:25px" v-if="personalForm.repayment_period == item.value"/>
                        <img src="/assets/spa/calender.svg" style="width:25px" v-else/>
                        <span style="width: 100%;text-align: right;margin-right: 5px;">{{item.label}}</span>
                        </el-radio-button>

                     </el-radio-group>
                </div>
                <!-- <fc-form-input
                    label="What do you need it for?"
                    prop="loan_reason"
                    :error="errors.loan_reason"
                    v-model="personalForm.loan_reason"
                    placeholder="Loan reason"
                    name="loan_reason"
                    :setFocus="setFocus"
                ></fc-form-input> -->
                </el-form>
            </div>
            <div class="dropdown-content">
                <div id="overlay" :style="open ? 'display:block' : 'display:none'">
                    <div class="hid-box" :style="open ? 'top:'+top+';position: sticky; margin: 20px; border-radius: 35px 35px 0px 0px;height:auto;z-index: 2;padding-top:18px' : 'top: 100%;z-index: 2;height:autoborder-radius: 35px 35px 0px 0px;padding-top:18px'">
                        <span class="material-icons" style="color: #C9CCD4;width: 100%;text-align: right;" @click="open = false">
                            close
                        </span>
                        <template v-if="type == 'loan_reason'">
                            <span>Loan Reason</span>
                            <div v-for="(item,index) in sections.loan_reason" id="selector" :selection="item.name" v-bind:key="index" style="width:100%" @click="setValue('loan_reason',item.label)">
                                <span style="margin-top: 20px;display: flex;width: 100%;justify-content: space-between;align-items: center;">{{item.label}} <img src="/assets/spa/icon-right.svg" style="height:10px"/></span>
                                <div class="divider" style="width: 100%;border-bottom: 1px solid #A8ACB8;margin-top: 20px;"></div>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </div>
        <div style="display: flex;width: 100%;justify-content: space-between;padding-top: 20px;">
            <primary-button id="primary_nav" type="button" style="width: 100%;margin-left:0px;justify-content: center;" :disabled="!personalForm.loan_amount_required || !personalForm.repayment_period" @click="submitLoanData">Next</primary-button>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { mapFields } from '../helpers.js' 
export default {
    data(){
        //Validate number only
        let validateAmount = (rule, value, callback) => {
            let ex = /^[0-9]\d*(\.\d+)?$/;

            if (typeof value === 'string' || value instanceof String){
                value = value.replace(/\s/g, '');
            }
            if (ex.test(value) === false) {
                console.log(value)
                this.errors.loan_amount_required = 'Please enter a valid amount (Numbers Only)'
                callback(new Error("Please enter a valid amount (Numbers Only)"));
            } else {
                callback();
            }
        };

        let validateMinAmount = (rule, value, callback) => {
            let ex = /^[0-9]\d*(\.\d+)?$/;
            if (typeof value === 'string' || value instanceof String){
                value = value.replace(/\s/g, '');
            }
            console.log(value)
            console.log('hello')
            try {
                if (ex.test(value) === false) {
                    this.errors.loan_amount_required = 'Please enter a valid amount (Numbers Only)'
                    callback(new Error("Please enter a valid amount (Numbers Only)"));
                } else if(parseFloat(value) > 350000) {
                    this.errors.loan_amount_required = 'Please input a value less than R350 000'
                    callback(new Error("Please input a value less than R350 000"));
                } else if(parseFloat(value) < 500) {
                    this.errors.loan_amount_required = 'Please input a value greater than R500'
                    callback(new Error("Please input a value greater than R500"));
                }else{
                    callback()
                }
            } catch (error) {
                callback(new Error("Please enter a valid amount (Numbers Only)"));
            }

        };

        return {
            errors:{
                loan_amount_required:''
            },
            repayment_periods: [
                {
                    value: 1,
                    label: "1 Month",
                },
                {
                    value: 3,
                    label: "3 Months",
                },
                {
                    value: 6,
                    label: "6 Months",
                },
                {
                    value: 12,
                    label: "12 Months",
                },
                {
                    value: 24,
                    label: "24 Months",
                },
                {
                    value: 36,
                    label: "36 Months",
                },
                {
                    value: 48,
                    label: "48 Months",
                },
                {
                    value: 60,
                    label: "60 Months",
                },
                {
                    value: 72,
                    label: "72 Months",
                },
            ],
            rules: {
                loan_amount_required: [
                    { required: true, validator: validateMinAmount, trigger: "blur" },
                ],
                repayment_period: [
                    { required: true, validator: validateAmount, trigger: "blur" },
                ],
                loan_reason: [
                    {
                        required: true,
                        message: "Please select a loan reason",
                        trigger: "change",
                    },
                ],
 
            },
            type:'',
            open: false,
            sections: {
                loan_reason:[

                    {
                        "name": "housing",
                        "label": "Housing/home improvement"
                    },
                    {
                        "name": "education",
                        "label": "Education"
                    },

                    {
                        "name": "funeral",
                        "label": "Funeral"
                    },
                    {
                        "name": "emergency",
                        "label": "Emergency"
                    },

                    {
                        "name": "spoil-myself",
                        "label": "I want to spoil myself"
                    },
                    {
                        "name": "other",
                        "label": "Other"
                    },
                ]
            }
        }
    },
    computed:{
        ...mapState({
            personalForm: state => state.application.personalLoan,
        }),
        ...mapFields({
            fields: ['loan_amount_required', 'repayment_period', 'loan_reason'],
            store: 'application',
            fieldMap: 'information',
            target: 'personalForm',
            mutation: 'updatePersonalLoanField'
        }),
    },
    created() {
         window.utag.view({
            event_name: 'page_view',
            page_name: 'vodapay: vodalend compare: loan details',
            page_section: 'vodalend compare',
            page_channel: 'mobile app',
            visitor_login_status: 'logged in',
            page_type: 'webview',
        });
    },
    methods:{
        preventSubmit(){
            if(this.personalForm.loan_amount_required && this.personalForm.repayment_period){
                this.submitLoanData()
            }
        },
        async setValue(type,val){
            await this.$store.dispatch('application/updatePersonalLoanField',{
                key:type,
                value: val
            })
            this.open = false
        },
        setFocus(type){
            this.top = 'inherit'
            this.open = true;
            this.type = type;
        },
        submitLoanData(){
            window.utag.link({
                event_name: 'ui_interaction',
                page_name: 'vodapay: vodalend compare: loan details',
                page_section: 'vodalend compare',
                page_channel: 'mobile app',
                visitor_login_status: 'logged in',
                page_type: 'webview',
                link_id: 'vodapay: vodalend compare: next',
                event_label: 'loan period',
                event_value: this.personalForm.repayment_period
            });
            this.errors.loan_amount_required = '';
            this.$refs['requirements'].validate(async valid => {
                if(valid){
                    await this.$store.dispatch('state/updateStatus', 'primary-income')
                    this.$router.push({name:'primary-income'})
                }else{
                    window.scrollTo(0, 0);
                    return false;
                }
            })
        },
        toKebab(string) {
            return string
                .split('')
                .map((letter) => {
                if (/[A-Z]/.test(letter)) {
                    return ` ${letter.toLowerCase()}`
                }
                return letter
                })
                .join('')
                .trim()
                .replace(/[_\s]+/g, '-')
        }
    },

}
</script>
<style >
.wpr {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  
  grid-gap: 10px 10px;
}
.wpr > div {
  border: 5px solid green;
  min-height: 50px;
}
@media (min-width: 430px) {
  .wpr {
    grid-template-columns: repeat(3, 1fr);
  }
}
</style>