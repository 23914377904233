<template>
    <theme-provider style="letter-spacing: 0.1px;">
        <navigation-bar/>
        <layout>
            <router-view></router-view>
        </layout>    
    </theme-provider>
</template>
<script>
import { ThemeProvider } from 'vue-styled-components'
import Layout from '../components/Layout'
import NavigationBar from '../components/Layout/navigation-bar.vue'
export default {
  components: {
    'theme-provider': ThemeProvider,
    'layout': Layout,
    'navigation-bar': NavigationBar
  },
  async mounted(){
    await this.$store.dispatch('state/initialState') 
  }
}

</script>