<template>
    <fc-styled-container>
        <el-main style="padding:0px">
            <slot></slot>
        </el-main>
    </fc-styled-container>
</template>
<script>
export default {
    data() {
        return {

        }
    }
}
</script>