import axios from "axios";
const base_url = process.env.VUE_APP_GATHR_ENDPOINT
const slug = process.env.VUE_APP_GATHR_TENANT;
// initial state
const state = () => ({
    bank_account: {},
    code: '',
    accounts: [],
    loading:true,
    loginSession: {},
    repeat:false,
    creditTransactions:[],
    repeatBankAccounts:[],
})


const getters = {
    bank_account: state => state.bank_account,
}

const actions = {
    async syncAccount({ commit}, payload) {
        const { data } = await axios.get(`${base_url}/${slug}/api/v0/bank-accounts/${payload.id}/sync?redirect_url=${window.location.href}&allow_redirect=false`)
        if(payload.repeat){
            commit('setRepeatApplication')
        }
        commit('updateSessionCode', data.data)
    },

    async initialState({ commit, dispatch, state, rootState},payload) {
        await dispatch('syncAccount',payload)
        axios.defaults.headers.common['Authorization'] = `Bearer ${rootState.application.gathrCustomer.api_token}`
        const { data } = await axios.get(`${base_url}/${slug}/api/v0/online-banking/${state.code}`);
        commit('updateState', data.data)
    },

    async updateOtp({ state }, payload) {

        await axios.post(`${base_url}/${slug}/api/v0/online-banking/${state.code}/update-otp`, payload);
    },

    async login({ state }, payload) {
        if(state.repeat){
            await axios.post(`${base_url}/${slug}/api/v0/online-banking/${state.code}/repeat-login`, payload);
        }else{
            await axios.post(`${base_url}/${slug}/api/v0/online-banking/${state.code}/login`, payload);
        }
    },

    async getBankAccounts({ commit, state }) {

        const { data } = await axios.get(`${base_url}/${slug}/api/v0/online-banking/${state.code}/accounts`);

        commit('setAccounts', data.data);
    },

    async updateBankAccounts({ state }, payload) {

        await axios.post(`${base_url}/${slug}/api/v0/online-banking/${state.code}/accounts`, payload);
    },
    
    async getTransactions({ commit, state }) {
        const { data } = await axios.get(`${base_url}/${slug}/api/v0/bank-accounts/${state.bank_account.id}/transactions?type=credit`);
        console.log(data)
        commit('setCreditTransactions', data.data.filter(tx=> tx.amount > 3000));
    },
    
}

const mutations = {
    updateState(state, bank_account) {
        state.bank_account = bank_account;
        state.loading = false
    },

    setAccounts(state, accounts) {
        state.accounts = accounts;
    },
    setCreditTransactions(state, transactions){
        state.creditTransactions = transactions
    },
    updateSessionCode(state, session){
        state.code = session.session_code
        state.loginSession = session;
    },
    setRepeatApplication(state){
        state.repeat = true
    },
    setRepeatApplications(state, accounts){
        state.repeatBankAccounts = accounts
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}