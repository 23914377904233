<template>
    <div class="_spa-container" style="height: 100%;">
        <div style="height: 100%;display: flex;flex-direction: column;justify-content: space-between;">
             <div class="_container-curved"></div>
            <div class="_wording-container" style="margin-top:0px">
                <img src="/assets/spa/fail-envelop.svg"/>
                <span class="_title" style="margin-top: 20px;font-weight: 400;padding: 0px 20px;font-size: 28px;">Unfortunately we couldn’t find you any loan offers</span>
                <span class="_text" style="padding:20px 25px">We are not able to match you with any of our lenders at this time.</span>
            </div>
            <div class="_button-container" style="flex-direction:column">
                <primary-button id="primary_nav" style="width:100%;margin-left: 0px;" @click="redirectToFaqs">FAQ's</primary-button>
                <secondary-button id="secondary_nav" style="width: 100%;justify-content: center;margin-left: 0px;margin-top: 10px;" @click="exitApp">Go to Home</secondary-button>
            </div>
        </div>
        <el-dialog
            :visible.sync="dialogVisible"
            :modal-append-to-body="false"
            :append-to-body="true"
            :close-on-press-escape="false"
            :lock-scroll="true"
            :show-close="false"
            :close-on-click-modal="false"
            size="small"
            custom-class="_consent-dialog"
        >
            <img src="/assets/spa/lightbulb.svg"/>
            <span style="text-align:center">Please tap the exit icon on the top right of your screen to go to the VodaPay app.</span>
            <secondary-button id="secondary_nav" @click="dialogVisible = false" style="width:100%;color:#C9CCD4">Got It <img src="/assets/spa/icon-right.svg" style="height: 10px;width: auto;margin: 0px;margin-left: 10px;"/></secondary-button>
        </el-dialog> 
    </div>
</template>

<script>

export default {
    data(){
        return {
            dialogVisible:false
        }
    }, 
    methods:{
        redirectToFaqs(){
            this.$store.dispatch('state/updateStatus', 'faqs')
            this.$router.push('/faqs/credit')
        },
        exitApp(){
            this.dialogVisible = true;
        }
    },

}
</script>
