/**
 * First we will load all of this project's JavaScript dependencies which
 * include Vue and Vue Resource. This gives a great starting point for
 * building robust, powerful web applications using Vue and Laravel.
 */

 require('./bootstrap');

import Vue from 'vue';
// Vue ElementUI
import ElementUI from 'element-ui';
import locale from "element-ui/lib/locale/lang/en";
import { PrimaryButton, SecondaryButton } from './components/elements/button/style';
import { StyledContainer } from './components/elements/layout/style';
import 'element-ui/lib/theme-chalk/index.css';
import VueAwesomeSwiper from 'vue-awesome-swiper'
import './styles/app.scss'
// import style (<= Swiper 5.x)
import 'swiper/swiper-bundle.css'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'

Bugsnag.start({
  apiKey: '7b50a5f7b319975d91f49410a9599507',
  plugins: [new BugsnagPluginVue()]
})

const bugsnagVue = Bugsnag.getPlugin('vue')
bugsnagVue.installVueErrorHandler(Vue)


Vue.use(VueAwesomeSwiper);
Vue.use(ElementUI, {
    locale
});
Vue.use(bugsnagVue)
// VueRouter
import VueRouter from 'vue-router'
import routes from './routes';
Vue.use(VueRouter)
Vue.component('primary-button', PrimaryButton);
Vue.component('secondary-button', SecondaryButton);
Vue.component('fc-styled-container', StyledContainer);
Vue.component('fc-form-input', require('./components/elements/input/input.vue').default);
Vue.component('online-banking-icon', require('./components/elements/svg/online-banking.vue').default);
Vue.component('statement-upload-icon', require('./components/elements/svg/statement-upload.vue').default);

Vue.component('loading-spinner', require('./components/elements/svg/loading.vue').default);
Vue.component('button-loading-spinner', require('./components/elements/svg/button-loading.vue').default);

Vue.component('online-error', require('./views/OnlineError.vue').default);

const router = new VueRouter({
    mode: 'history',
    routes
});

import App from './views/App'
import {store} from './store'

new Vue({
    el: '#app',
    components: {
        'app': App
    },
    watch: {
        $route: {
            immediate: true,
            handler(to) {
                document.title = to.meta.title || '';
            }
        },
    },
    template: '<app></app>',
    store,
    router
})