<template>
  <div class="_spa-container">
    <div class="_loading-screens" v-if="acceptingOffer">
      <loading-spinner :width="100" />
    </div>
    <div class="_statement-content" v-if="!displayFinalResult">
      <div>
        <div
          class="_detailed-offer _selected_offer "
          v-if="selectedOffer.company_name == 'Boodle'"
        >
          <div class="_transparent-header _top-header ">
            <template v-if="selectedOffer.company_wide_logo_url !== null">
              <img
                :src="selectedOffer.company_wide_logo_url"
                @error="handleImageLoadError($event)"
                alt=""
              />
            </template>
            <template v-else-if="selectedOffer.company_logo_url !== null">
              <img
                :src="selectedOffer.company_logo_url"
                @error="handleImageLoadError($event)"
                alt=""
              />
            </template>
            <template v-else>
              <img
                src="/themes/rainlab-vanilla/assets/icons/finance/52-money-box.svg"
                @error="handleImageLoadError($event)"
                alt=""
              />
            </template>
          </div>
          <div class="_offer-content">
            <span class="_grey-text"
              >Details of your personalised loan offer:</span
            >

            <span
              class="_light-text"
              style="margin-top:0px"
              v-if="
                selectedOffer.live_score &&
                  selectedOffer.live_score.ProductDescription
              "
              >{{ selectedOffer.live_score.ProductDescription }}</span
            >
            <div class="_more-info ">
              <div class="_section">
                <p class="left" style="color:white">Loan Amount:</p>
                <p
                  class="right"
                  style="color:white"
                  v-if="
                    selectedOffer.live_score &&
                      selectedOffer.live_score.LoanAmount > 0
                  "
                >
                  R{{
                    new Intl.NumberFormat().format(
                      selectedOffer.live_score.LoanAmount
                    )
                  }}
                </p>
              </div>
              <div
                class="_section"
                v-if="
                  selectedOffer.live_score &&
                    selectedOffer.live_score.TotalFees > 0
                "
              >
                <p
                  class="left"
                  style="color:white; font-weight:300;margin-top:0"
                >
                  Total Interest & Fees:
                </p>
                <p
                  class="right"
                  style="color:white; font-weight:300;margin-top:0"
                >
                  R{{
                    new Intl.NumberFormat().format(
                      selectedOffer.live_score.TotalFees
                    )
                  }}
                </p>
              </div>
              <div
                class="_section"
                style="border-top: 1px solid;margin-top: 5px;border-bottom: 1px solid;border-color: rgb(255 255 255 / 43%);"
              >
                <p
                  class="left"
                  style="color:white; margin-top:5px;margin-bottom:5px"
                >
                  Total Repayment:
                </p>
                <p
                  class="right"
                  style="color:white; margin-top:5px;margin-bottom:5px"
                >
                  R{{
                    new Intl.NumberFormat().format(
                      selectedOffer.live_score.TotalFees +
                        selectedOffer.live_score.LoanAmount
                    )
                  }}
                </p>
              </div>
              <div
                class="_section"
                v-if="
                  selectedOffer.live_score && selectedOffer.live_score.Term > 0
                "
              >
                <p class="left">Loan Term:</p>
                <p class="right" v-if="selectedOffer.live_score.PaydayLoan">
                  {{
                    selectedOffer.live_score.Term == 1
                      ? selectedOffer.live_score.Term + " day"
                      : selectedOffer.live_score.Term + " days"
                  }}
                </p>
                <p class="right" v-else>
                  {{
                    selectedOffer.live_score.Term == 1
                      ? selectedOffer.live_score.Term + " month"
                      : selectedOffer.live_score.Term + " months"
                  }}
                </p>
              </div>
              <div
                class="_section"
                v-if="
                  selectedOffer.live_score &&
                    selectedOffer.live_score.InterestRate > 0
                "
              >
                <p class="left">Interest Rate:</p>
                <p class="right">
                  {{ selectedOffer.live_score.InterestRate }}%
                </p>
              </div>
              <div
                class="_section"
                v-if="
                  selectedOffer.live_score &&
                    selectedOffer.live_score.TotalInterest > 0
                "
              >
                <p class="left">Total interest to be paid:</p>
                <p class="right">
                  R{{
                    new Intl.NumberFormat().format(
                      selectedOffer.live_score.TotalInterest
                    )
                  }}
                </p>
              </div>
              <div
                class="_section"
                v-if="
                  selectedOffer.live_score &&
                    selectedOffer.live_score.InitiationFee > 0
                "
              >
                <p class="left">Initiation fee:</p>
                <p class="right">
                  R{{
                    new Intl.NumberFormat().format(
                      selectedOffer.live_score.InitiationFee
                    )
                  }}
                </p>
              </div>
              <div
                class="_section"
                v-if="
                  selectedOffer.live_score &&
                    selectedOffer.live_score.TotalServiceFee > 0
                "
              >
                <p class="left">Total service fee:</p>
                <p class="right">
                  R{{
                    new Intl.NumberFormat().format(
                      selectedOffer.live_score.TotalServiceFee
                    )
                  }}
                </p>
              </div>
              <div
                class="_section"
                v-if="
                  selectedOffer.live_score &&
                    selectedOffer.live_score.InsurancePremium > 0
                "
              >
                <p class="left">Total insurance premium:</p>
                <p class="right">
                  R{{
                    new Intl.NumberFormat().format(
                      selectedOffer.live_score.InsurancePremium
                    )
                  }}
                </p>
              </div>
              <div
                class="_section"
                v-if="
                  selectedOffer.live_score &&
                    selectedOffer.live_score.RepaymentDate
                "
              >
                <p class="left">Payment due date:</p>
                <p class="right">
                  {{ selectedOffer.live_score.RepaymentDate }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          class="_selected_offer"
          v-else-if="
            (selectedOffer.live_score != -2 && !selectedOffer.live_score) ||
              selectedOffer.id === 116
          "
        >
          <div class="_top-header">
            <template v-if="selectedOffer.company_wide_logo_url !== null">
              <img
                :src="selectedOffer.company_wide_logo_url"
                @error="handleImageLoadError($event)"
                alt=""
              />
            </template>
            <template v-else-if="selectedOffer.company_logo_url !== null">
              <img
                :src="selectedOffer.company_logo_url"
                @error="handleImageLoadError($event)"
                alt=""
              />
            </template>
            <template v-else>
              <img
                src="/themes/rainlab-vanilla/assets/icons/finance/52-money-box.svg"
                @error="handleImageLoadError($event)"
                alt=""
              />
            </template>
          </div>
          <div class="_offer-content">
            <span
              class="_big-loan-amount"
              v-if="
                selectedOffer.live_score &&
                  selectedOffer.live_score.LoanAmount > 0
              "
              >R{{
                new Intl.NumberFormat().format(
                  selectedOffer.live_score.LoanAmount
                )
              }}</span
            >
            <span
              class="_big-loan-amount"
              v-else-if="
                selectedOffer.live_score &&
                  selectedOffer.live_score.min &&
                  selectedOffer.live_score.max > 0
              "
              >R{{
                new Intl.NumberFormat().format(selectedOffer.live_score.min)
              }}
              - R{{
                new Intl.NumberFormat().format(selectedOffer.live_score.max)
              }}</span
            >
            <span class="_big-loan-amount" v-else
              >R{{
                new Intl.NumberFormat().format(
                  personalLoan.loan_amount_required
                )
              }}</span
            >
            <span
              class="_light-text"
              style="margin-top:0px"
              v-if="
                selectedOffer.live_score &&
                  selectedOffer.live_score.ProductDescription
              "
              >{{ selectedOffer.live_score.ProductDescription }}</span
            >
            <span class="_light-text"
              >Below is an estimate of the loan terms you may be eligible
              for:</span
            >
            <span class="_terms">Loan Terms</span>
            <div class="_more-info">
              <div
                class="_section"
                v-if="
                  selectedOffer.live_score &&
                    selectedOffer.live_score.LoanAmount > 0
                "
              >
                <p class="left">Principal Amount</p>
                <p class="right">
                  R
                  {{
                    new Intl.NumberFormat().format(
                      selectedOffer.live_score.LoanAmount
                    )
                  }}
                </p>
              </div>
              <div
                class="_section"
                v-if="
                  selectedOffer.live_score && selectedOffer.live_score.min > 0
                "
              >
                <p class="left">Minimum Amount</p>
                <p class="right">
                  R
                  {{
                    new Intl.NumberFormat().format(selectedOffer.live_score.min)
                  }}
                </p>
              </div>
              <div
                class="_section"
                v-if="
                  selectedOffer.live_score && selectedOffer.live_score.max > 0
                "
              >
                <p class="left">Maximum Amount</p>
                <p class="right">
                  R
                  {{
                    new Intl.NumberFormat().format(selectedOffer.live_score.max)
                  }}
                </p>
              </div>
              <div class="_section" v-else>
                <p class="left">Principal Amount</p>
                <p class="right">
                  R{{
                    new Intl.NumberFormat().format(
                      personalLoan.loan_amount_required
                    )
                  }}
                </p>
              </div>
              <div class="_section" v-if="selectedOffer.probability !== null">
                <p class="left">Chance of success</p>
                <p class="right">{{ selectedOffer.probability }}%</p>
              </div>
              <div
                class="_section"
                v-if="
                  selectedOffer.live_score &&
                    selectedOffer.live_score.InterestRate > 0
                "
              >
                <p class="left">Interest Rate</p>
                <p class="right">
                  {{ selectedOffer.live_score.InterestRate }}%
                </p>
              </div>
              <div
                class="_section"
                v-if="
                  selectedOffer.live_score &&
                    selectedOffer.live_score.TermAllowed > 0
                "
              >
                <p class="left">Term up to</p>
                <p class="right" v-if="selectedOffer.live_score.PaydayLoan">
                  {{ selectedOffer.live_score.TermAllowed }} Days
                </p>
                <p class="right" v-else>
                  {{ selectedOffer.live_score.TermAllowed }} Months
                </p>
              </div>
              <div
                class="_section"
                v-if="
                  selectedOffer.live_score &&
                    selectedOffer.live_score.MonthlyRepayment > 0
                "
              >
                <p class="left">Monthly Installment</p>
                <p class="right">
                  R{{
                    new Intl.NumberFormat().format(
                      selectedOffer.live_score.MonthlyRepayment
                    )
                  }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="_selected_offer" v-else-if="selectedOffer.live_score != -2">
          <div class="_top-header">
            <template v-if="selectedOffer.company_logo_url !== null">
              <img
                :src="selectedOffer.company_logo_url"
                @error="handleImageLoadError($event)"
                alt=""
              />
            </template>
            <template v-else>
              <img
                src="/themes/rainlab-vanilla/assets/icons/finance/52-money-box.svg"
                @error="handleImageLoadError($event)"
                alt=""
              />
            </template>
          </div>
          <div class="_offer-content">
            <template v-if="selectedOffer.live_score != null">
              <template v-if="selectedOffer.live_score !== 'undefined'">
                <span
                  class="_big-loan-amount"
                  v-if="
                    selectedOffer.live_score &&
                      selectedOffer.live_score.LoanAmount > 0
                  "
                  >R{{
                    new Intl.NumberFormat().format(
                      selectedOffer.live_score.LoanAmount
                    )
                  }}</span
                >
                <span
                  class="_big-loan-amount"
                  v-else-if="
                    selectedOffer.live_score &&
                      selectedOffer.live_score.min &&
                      selectedOffer.live_score.max > 0
                  "
                  >R{{
                    new Intl.NumberFormat().format(selectedOffer.live_score.min)
                  }}
                  - R{{
                    new Intl.NumberFormat().format(selectedOffer.live_score.max)
                  }}</span
                >
                <span class="_big-loan-amount" v-else
                  >R{{
                    new Intl.NumberFormat().format(
                      personalLoan.loan_amount_required
                    )
                  }}</span
                >
              </template>
            </template>
            <span
              class="_light-text"
              style="margin-top:0px"
              v-if="
                selectedOffer.live_score &&
                  selectedOffer.live_score.ProductDescription
              "
              >{{ selectedOffer.live_score.ProductDescription }}</span
            >
            <span class="_light-text"
              >Below is an estimate of the loan terms you may be eligible
              for:</span
            >
            <span class="_terms">Loan Terms</span>
            <div class="_more-info">
              <template v-if="selectedOffer.live_score !== 'undefined'">
                <div
                  class="_section"
                  v-if="selectedOffer.live_score.LoanAmount"
                >
                  <p class="left">Principal Amount</p>
                  <p class="right">
                    R{{
                      new Intl.NumberFormat().format(
                        selectedOffer.live_score.LoanAmount
                      )
                    }}
                  </p>
                </div>
                <div
                  class="_section"
                  v-if="
                    selectedOffer.live_score.InterestRate &&
                      selectedOffer.live_score.InterestRate > 0
                  "
                >
                  <p class="left">Interest rate</p>
                  <p v-if="selectedOffer.id == 150" class="right">
                    {{ selectedOffer.live_score.InterestRate }}% Monthly
                  </p>
                  <p v-else class="right">
                    {{ selectedOffer.live_score.InterestRate }}%
                  </p>
                </div>
                <div
                  class="_section"
                  v-if="
                    selectedOffer.live_score && selectedOffer.live_score.min > 0
                  "
                >
                  <p class="left">Minimum Amount</p>
                  <p class="right">
                    R
                    {{
                      new Intl.NumberFormat().format(
                        selectedOffer.live_score.min
                      )
                    }}
                  </p>
                </div>
                <div
                  class="_section"
                  v-if="
                    selectedOffer.live_score && selectedOffer.live_score.max > 0
                  "
                >
                  <p class="left">Maximum Amount</p>
                  <p class="right">
                    R
                    {{
                      new Intl.NumberFormat().format(
                        selectedOffer.live_score.max
                      )
                    }}
                  </p>
                </div>
                <div
                  class="_section"
                  v-if="selectedOffer.live_score.TermAllowed"
                >
                  <p class="left">Term up to</p>
                  <p class="right">
                    {{ selectedOffer.live_score.TermAllowed }} Months
                  </p>
                </div>
                <div
                  class="_section"
                  v-if="
                    selectedOffer.live_score &&
                      selectedOffer.live_score.MonthlyRepayment > 0
                  "
                >
                  <p class="left">Monthly Installment</p>
                  <p class="right">
                    R{{
                      new Intl.NumberFormat().format(
                        selectedOffer.live_score.MonthlyRepayment
                      )
                    }}
                  </p>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
      <span
        style="text-align: center;color: #A8ACB8;"
        v-if="type == 'apply-now' && selectedOffer.company_name == 'Boodle'"
        >By clicking ‘Accept & proceed’ you agree to
        {{ selectedOffer.company_name }}’s <br /><span
          @click="goToTerms(selectedOffer.company_name)"
          style="text-decoration: underline;"
          >Terms & Conditons</span
        >
        and
        <span
          @click="goToPrivacy(selectedOffer.company_name)"
          style="text-decoration: underline;"
          >Privacy Policy</span
        >
        .</span
      >
      <div>
        <div
          v-if="type == 'apply-now'"
          style="display: flex;width: 100%;justify-content: space-between;flex-direction:column; padding-top: 20px;"
        >
          <primary-button
            id="primary_nav"
            type="button"
            style="width: 100%; margin-left:0px;justify-content: center;"
            @click="
              acceptOffer(
                selectedOffer.id,
                selectedOffer.live_score ? selectedOffer.live_score : null
              )
            "
          >
            <span>Accept & proceed</span>
          </primary-button>
          <secondary-button
            id="secondary_nav"
            style="width:100%;margin-top:10px"
            @click="goBack"
            >Back to offers</secondary-button
          >
        </div>
        <div
          style="display: flex;width: 100%;justify-content: space-between;flex-direction:column"
          v-else
        >
          <template>
            <primary-button
              id="primary_nav"
              v-if="
                selectedOffer.userable.partner_type == 'api' ||
                  selectedOffer.userable.partner_type == 'dashboard'
              "
              type="button"
              style="width: 100%; margin-left:0px;justify-content: center;"
              @click="
                acceptOffer(
                  selectedOffer.id,
                  selectedOffer.live_score ? selectedOffer.live_score : null
                )
              "
            >
              <span>Complete Application</span>
            </primary-button>
            <primary-button
              id="primary_nav"
              v-else-if="selectedOffer.userable.partner_type == 'url'"
              type="button"
              style="width: 100%; margin-left:0px;justify-content: center;"
              @click="acceptOffer(selectedOffer.id)"
              >Complete Application</primary-button
            >
          </template>
          <secondary-button
            id="secondary_nav"
            style="width:100%;margin-left:0px;margin-top: 10px;"
            @click="goBack"
            >Back to offers</secondary-button
          >
        </div>
      </div>
    </div>
    <div
      style="height: calc(100vh - 44px);display: flex;flex-direction: column;justify-content: space-between;"
      v-else-if="displayFinalResult && partnerStatus == false"
    >
      <div class="_wording-container" style="margin-top:60px">
        <img src="/assets/spa/fail-envelop.svg" />
        <span class="_title" style="margin-top: 20px;"
          >Application declined</span
        >
        <span class="_text" style="padding:0px 25px"
          >Unfortunately your application was declined by the lender.</span
        >
      </div>
      <div class="_button-container" style="flex-direction:column">
        <primary-button
          id="primary_nav"
          style="width:100%;margin-left: 0px;"
          @click="redirectToFaqs"
          >FAQ's</primary-button
        >
        <secondary-button
          id="secondary_nav"
          style="width: 100%;justify-content: center;margin-left: 0px;margin-top: 10px;"
          @click="reportIssue"
          >Report Issue</secondary-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      errors: {},
      open: false,
      acceptingOffer: false,
      partner: {},
      displayFinalResult: false,
      partnerStatus: false,
      apiUrl: process.env.VUE_APP_ENGINE_URL,
      apiKey: process.env.VUE_APP_ENGINE_KEY,
    };
  },
  computed: {
    ...mapGetters({
      personalLoan: "application/personalLoan",
      offers: "offers/offers",
      visiblePartners: "offers/visiblePartners",
      selectedOffer: "offers/selectedOffer",
      type: "application/type",
    }),
  },
  created() {
    window.utag.view({
      event_name: "page_view",
      page_name: "vodapay: vodalend compare: loan summary details",
      page_section: "vodalend compare",
      page_channel: "mobile app",
      visitor_login_status: "logged in",
      page_type: "webview",
    });
  },
  mounted() {
    console.log(this.selectedOffer);
  },
  methods: {
    acceptOffer(id, live_score = null) {
      window.utag.link({
        event_name: "ui_interaction",
        page_name: "vodapay: vodalend compare: loan summary details",
        page_section: "vodalend compare",
        page_channel: "mobile app",
        visitor_login_status: "logged in",
        page_type: "webview",
        link_id: "vodapay: vodalend compare: accept & proceed",
      });
      this.acceptingOffer = true;
      if (this.personalLoan.urlPartner == true) {
        this.acceptingOffer = false;
      }
      if (id > 0) {
        this.personalLoan.partner_id = id;
        this.personalLoan.live_score = live_score;
      }

      axios
        .post(
          this.apiUrl + `/api/v1/accept`,
          {
            partner_id: id,
            live_score: live_score,
            hashid: this.offers.id,
            type: this.selectedOffer.end_to_end ? "apply-now" : "get-quote",
          },
          {
            headers: { Authorization: "Bearer " + this.apiKey },
          }
        )
        .then((response) => {
          this.partner = response.data;
          if (this.partner.status == "failed") {
            this.acceptingOffer = false;
            this.partnerStatus = false;
            window.scrollTo(0, 0);
            this.acceptingOffer = false;
            this.partnerStatus = false;
            this.displayFinalResult = true;
            return;
          } else {
            if (this.selectedOffer.end_to_end) {
              this.$router.push({ name: "redirect-url" });
              // let companyName = this.selectedOffer.company_name;
              // let fraudFlag = 'no'
              // if(this.selectedOffer.live_score && this.selectedOffer.live_score.HasFraudFlag){
              //     fraudFlag = 'yes'
              // }
              // if(companyName == 'Boodle'){
              //     this.$router.push({name:'success',params:{type:companyName.toLocaleLowerCase(), fraud_flag:fraudFlag}})

              // }else{
              //     //this.$router.push({ name: 'redirect-url'})
              //     this.$router.push({name:'success',params:{type:'sms'}})
              // }
            } else {
              this.$router.push({ name: "thank-you" });
            }
          }
        })
        .catch(() => {
          this.$router.push({ name: "technical-error" });
        });
    },
    redirectToFaqs() {
      this.$store.dispatch("state/updateStatus", "faqs");
      this.$router.push("/faqs/credit");
    },
    reportIssue() {
      this.$router.push({
        name: "report-issue",
        params: { partner: this.selectedOffer.company_name },
      });
    },
    goBack() {
      window.utag.link({
        event_name: "ui_interaction",
        page_name: "vodapay: vodalend compare: loan summary details",
        page_section: "vodalend compare",
        page_channel: "mobile app",
        visitor_login_status: "logged in",
        page_type: "webview",
        link_id: "vodapay: vodalend compare: back to offers",
      });
      this.$router.go(-1);
    },
    goToPrivacy(name) {
      console.log(name);
      // let slug = name.toLocaleLowerCase().replace(/ /g,"-");
      this.$router.push({
        name: "privacy-policy",
        params: { partner: "boodle" },
      });
    },
    goToTerms(name) {
      console.log(name);
      // let slug = name.toLocaleLowerCase().replace(/ /g,"-");
      this.$router.push({
        name: "terms-and-conditions",
        params: { partner: "boodle" },
      });
    },
    handleImageLoadError() {},
    wait(ms) {
      var start = new Date().getTime();
      var end = start;
      while (end < start + ms) {
        end = new Date().getTime();
      }
    },
  },
};
</script>
