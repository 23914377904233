<template>
<div class="_spa-container" :style="loading && otpSubmitted && 'justify-content: center;'">
    <div class="_loading-screens" v-if="loading && otpSubmitted">
        <loading-spinner :width="100"/>
        <div class="_wording-container">
            <span class="_text" v-if="fetchingAccounts || loadingMessage">{{ loadingMessage }}</span>
            <span class="_text" v-else-if="completed">We are busy processing your statements. This may take up to 90 seconds. If you selected multiple accounts, processing may take slightly longer.</span>
            <span class="_text" v-if="counterDuration && counterMessage" style="position: relative;">
                <small>{{ counterMessage }} Please do not close...</small>
                <span style="display: flex;justify-content: center;align-items: center;margin-top: 10px;">
                    <small><strong>{{  counterDuration }}</strong></small>
                </span>
            </span>
         </div>
    </div>

    <div class="_online-container" v-else-if="onlineReady">
        <div>
            <div v-if="showAccountsModal" class="_acounts-container" style="height:calc(100vh - 36px)">
                <div>
                    <div class="_wording-container">
                        <span class="_title">Select Day-to-day Accounts</span>
                        <span class="_text">Please select all the accounts which you use to send and receive money, pay off debt, and buy goods.</span>
                    </div>
                    <div class="_accounts-info-container">
                        <div class="_custom-radio" style="margin-top:30px">
                            <div class="_radio" v-for="account in accounts" :key="account.uuid" style="border-bottom: 1px solid #a8acb8;padding-bottom:10px;padding-top:10px">
                                     <div class="_account-details">
                                        <span class="_thin">{{account.accountName}}</span>
                                        <span class="_bold">{{account.accountNumber}}</span>
                                        <span class="_thin">Balance: {{account.accountBalance}}</span>
                                    </div>
                                <div>
                                    <img class="_tick-svg" src="/assets/spa/tick-on.svg" v-if="accountsForm.bank_accounts.includes(account.accountNumber)" @click="selectAccount(account.accountNumber)"/>
                                    <img class="_tick-svg" src="/assets/spa/tick-off.svg" v-else @click="selectAccount(account.accountNumber)"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="_button-container" v-if="accounts.length > 1">
                    <secondary-button id="secondary_nav" style="width: 40%;">Change</secondary-button>
                    <primary-button id="primary_nav" type="button" style="width: 60%;margin-left:10px;justify-content: center"
                                    @click="updateBankAccounts">
                        <span>Confirm</span>
                    </primary-button>
                </div>
                <div class="_button-container" v-else>
                    <primary-button id="primary_nav" type="button" style="width: 100%;justify-content: center"
                                    @click="updateBankAccounts">
                        <span>Confirm</span>
                    </primary-button>
                </div>
            </div>
            <div class="_wording-container" v-else-if="!completed && !(loading && otpSubmitted)">
                <span class="_title">Online Login</span>
                <span class="_text">The fastest and easiest way to retrieve your bank statements.</span>
            </div>
            <el-form v-if="!otpSubmitted && !statementErrors.error && !completed"
                label-width="120px"
                label-position="top">
                <div class="__section">
                    <div class="__input" style="justify-content: center;align-items: center;display: flex !important;width: 100%;">
                        <img v-if="bank_account.bank == 'capitec'" :src="'/assets/banks/small-logos/capitec-square.jpg'" style="width: 120px;margin-top:15px; border-radius:60px"/>
                        <img v-else-if="bank_account.bank == 'standard-bank'" :src="'/assets/banks/small-logos/stdbank-square.jpg'" style="width: 120px;margin-top:15px; border-radius:60px"/>
                        <img v-else :src="'/assets/banks/small-logos/'+bank_account.bank+'.svg'" style="width: 120px;margin-top:15px">
                    </div>
                </div>
                <div class="__section" v-if="!loading || bankLoginForm.resubmit === true">
                    <div class="__input"  style="padding:0px 10px">
                        <div class="__col">
                            <fc-form-input
                                :label="bank_account.bank === 'absa' ? 'Account Number' : bank_account.bank === 'capitec' ? 'Account Number/Username' : 'Username'"
                                prop="username"
                                type="text"
                                :loginForm="true"
                                :error="errors.username"
                                v-model="bankLoginForm.credentials.username"
                            ></fc-form-input>
                        </div>
                        <div class="__col" v-if="bank_account.has_pin">
                            <fc-form-input
                                label="Pin"
                                prop="pin"
                                ref="pin"
                                type="password"
                                :showPassword="true"
                                :loginForm="true"
                                :error="errors.pin"
                                v-model="bankLoginForm.credentials.pin"
                            ></fc-form-input>
                        </div>
                        <div class="__col">
                            <fc-form-input
                                :label="bank_account.bank === 'capitec' ? 'Password/Remote Pin':'Password'"
                                prop="password"
                                ref="password"
                                type="password"
                                :showPassword="true"
                                :loginForm="true"
                                :error="errors.password"
                                v-model="bankLoginForm.credentials.password"
                                @keyup.enter="bankLogin()"
                            ></fc-form-input>
                        </div>
                    </div>
                    <div v-if="initialLoading && initialLoginPopup" class="_login-error">
                        <span v-if="bank_account.bank === 'capitec'">Please enter the Remote PIN in your Capitec banking app to approve the login.</span>
                        <span v-else>Please approve any login requests on your Online Banking App.</span>
                    </div>
                    <div v-if="initialLoading && initialLoginPopup" class="_login-error">
                        <span>Retrieving your bank statements may take up to a minute depending on your bank. Please do not exit the Vodalend Compare mini-app during this time.</span>
                        <span class="_login-error-button" @click="closeInitialLoginPopup" v-if="loginTimer">Close</span>
                    </div>
                    <div v-if="loginError" class="_login-error">
                        <img src="/assets/spa/warning.svg"/>
                        <span>{{ loginError }}</span>
                    </div>
                    <div v-if="loginError && loginFailedAttempts == 2 && loginPopup" class="_login-error">
                        <span>On your 3rd failed login attempt your account will be locked. Please contact your bank for more details.</span>
                        <span class="_login-error-button" @click="closeLoginPopup">Close</span>
                    </div>
                    <div v-if="loginError && loginFailedAttempts == 3 && loginPopup" class="_login-error" style="padding-right: 30px;">
                        <span>Your account has been locked. Please contact for more details.</span>
                        <span class="_login-error-button" @click="closeLoginPopup">Close</span>
                    </div>
                </div>
                <div class="__section" v-if="(bank_account.has_otp && !otpSubmitted && showOtp)">
                    <div class="__input">
                        <div class="__col">
                            <fc-form-input
                                label="Onetime-Pin"
                                prop="otp"
                                type="text"
                                :error="errors.otp"
                                v-model="otpForm.otp"
                            ></fc-form-input>
                        </div>
                    </div>
                </div>
                <div class="_wording-container" style="align-items: flex-start;margin-bottom: 25px;margin-top: 10px;padding: 0px 10px;" v-if="loginFailedAttempts == 0 && !initialLoading">
                    <span class="_text" style="margin-top: 5px;font-size: 16px;text-align: start;margin-left: 15px;display: flex;align-items: flex-start;"><img src="/assets/spa/tick.svg" style="width: 15px;margin-right: 15px;margin-top: 7px;"/><span>Secure, fully encrypted connection</span></span>
                    <span class="_text" style="margin-top: 5px;font-size: 16px;text-align: start;margin-left: 15px;display: flex;align-items: flex-start;"><img src="/assets/spa/tick.svg" style="width: 15px;margin-right: 15px;margin-top: 7px;"/><span>Details are not stored forever</span></span>
                    <span class="_text" style="margin-top: 5px;font-size: 16px;text-align: start;margin-left: 15px;display: flex;align-items: flex-start;"><img src="/assets/spa/tick.svg" style="width: 15px;margin-right: 15px;margin-top: 7px;"/><span>Only get statements, don’t send/receive money</span></span>
                </div>
                <div class="_wording-container" v-if="loginTimer && !initialLoginPopup" style="align-items: center;padding: 0px 10px;bottom: 0px;width: 100%;margin: 0 auto;">
                    <span class="_text">
                        <span>{{ counterMessage }} </span>
                        <span style="display: flex;justify-content: center;align-items: center;margin-top: 10px;">
                            <small>Please do not close... <strong>{{  counterDuration }}</strong></small>
                        </span>
                    </span>
                </div>
            </el-form>
        </div>
        <div class="_button-container" v-if="(bank_account.has_otp && showOtp) && !otpSubmitted">
            <primary-button id="primary_nav" style="width: 100%;margin-left:0px" type="button" :disabled="!otpForm.otp"
                            class="" @click="submitOtp()">
                <span>Submit OTP</span>
                <i class="material-icons" style="margin-left:8px">keyboard_arrow_right</i>
            </primary-button>
        </div>
        <div class="_button-container" v-else-if="!showAccountsModal" :style="loginTimer ? 'margin-top:30px' : 'margin-top:30px;margin-bottom:350px'">
            <secondary-button id="secondary_nav" style="width: 40%;" @click="redirectToFaqs" v-if="(!statementErrors.error && !loading && bank_account.canLogin === true && bankLoginForm.resubmit === false) || (!statementErrors.error && !loading) && (!loginTimer && initialLoginPopup || initialLoginPopup)">FAQ's</secondary-button>
            <primary-button id="primary_nav"
                            style="width: 60%;margin-left:10px;justify-content: center;"
                            type="button"
                            v-if="(!statementErrors.error && !loading) && (!loginTimer && initialLoginPopup || initialLoginPopup)"
                            :disabled="!this.bankLoginForm.credentials.username || !this.bankLoginForm.credentials.password || loginFailedAttempts == 3"
                            class="" @click="bankLogin()">
                <i v-if="isLoggedIn && initialLoading" class='el-icon-check'/> 
                <button-loading-spinner v-else-if="initialLoading"/> 
                <span v-else-if="!initialLoading">Get Statements</span>
            </primary-button>
        </div>
    </div>
    <online-error  v-else-if="onlineFailed" :message="statementErrors.error" :displayReportError="failedAttempts < 2" :retry="retryLogin" :code="error.code ? error.code : false"/>
    <el-dialog
        :visible.sync="cancelPopup"
        :modal-append-to-body="false"
        :append-to-body="true"
        :close-on-press-escape="false"
        :lock-scroll="true"
        :show-close="false"
        :close-on-click-modal="false"
        size="small"
        custom-class="_consent-dialog"
    >
        <img src="/assets/spa/lightbulb.svg"/>
        <span style="text-align:center;font-size:16px;font-weight: 600;">Are you sure?</span>
        <span style="text-align:center;font-size: 14px;font-weight: 400;">Are you sure you want to cancel your application?</span>
        <span style="text-align:center;font-size: 14px;font-weight: 400;">If you’re sure you want to cancel, tap the exit icon on the top right of your screen to go to the VodaPay app.</span>
        <secondary-button id="secondary_nav" @click="cancelPop()" style="width:100%;font-size:16px"> Got it<img src="/assets/spa/icon-right.svg" style="height: 10px;width: auto;margin: 0px;margin-left: 10px;"/></secondary-button>
    </el-dialog> 
</div>
</template>
<script>
import api from '../api'
import {mapGetters, mapState} from "vuex";
import {validateAmount} from '../helpers'
import crypto from 'crypto';
// eslint-disable-next-line no-unused-vars
// import Pusher from 'pusher'

export default {
    data() {
        return {
            activeTab:'yes',
            id:'',
            cancelPopup:false,
            status:'online-banking',
            bankLoginForm:{
                bank: null,
                credentials: {
                    username: null,
                    pin: null,
                    password: null,
                },
                session_code: null,
                slug: process.env.VUE_APP_GATHR_TENANT,
                iv: null,
                resubmit: false,
            },
            otpForm: {
                otp: null
            },
            errors: {
                username: '',
                password: '',
                pin: ''
            },
            statementErrors: {
                error: '',
                validations: []
            },
            failedAttempts:0,
            fetchingAccounts:false,
            loginFailedAttempts:0,
            loginError: null,
            completed: false,
            status_code: null,
            activeCollapse: null,
            otpSubmitted: false,
            timer: null,
            counter:0,
            errorCount:0,
            showAccountsModal: false,
            canLogin: false,
            initialLoginPopup:true,
            accountsForm: {
                bank_accounts: []
            },
            step:'online-banking',
            loginPopup:true,
            loading:false,
            validations: [],
            isIframe: false,
            initialLoading: false,
            isLoggedIn: false,
            edittingIncome:false,
            incomeChangeForm:{
                id:'',
                income_source:'',
                income:''
            },
            //rules
            rules: {
                income: [
                    { required: true, validator: validateAmount, trigger: "blur" },
                ],
                income_source: [
                    { required: true, trigger: "blur" },
                ],
                expenses: [
                    { required: true, validator: validateAmount, trigger: "blur" },
                ],
            },
            //counters
            error:{},
            counterDuration: null,
            interval: null,
            counterMessage: null,
            loadingMessage: null,
            loginTimer: false,
            showOtp: false
        }
    },
    computed: {
        ...mapState({
            bank_account: state => state.banking.bank_account,
            session_code: state => state.banking.code,
            accounts: state => state.banking.accounts,
            bankAccount: state => state.application.bankAccount,
            loginSession: state => state.banking.loginSession
        }),
        ...mapGetters('application', {
            bankAccount: 'bankAccount'
        }),
        onlineReady(){
            return this.step == 'online-banking' && !(this.statementErrors.error && this.status_code === 'bank_scraper_error')
        },
        onlineFailed(){
            return this.statementErrors.error && this.status_code === 'bank_scraper_error' || this.step == 'error'
        }
    },
    created() {
         window.utag.view({
            event_name: 'page_view',
            page_name: 'vodapay: vodalend compare:bank statements online login',
            page_section: 'vodalend compare',
            page_channel: 'mobile app',
            visitor_login_status: 'logged in',
            page_type: 'webview',
        });
    },
    async mounted(){
        this.loading = true
        await this.$store.dispatch('banking/initialState', {id:this.bankAccount.id, repeat: this.bankAccount.repeat});
        this.loading = false
    },
    methods: {
        /**
         * Client side encryption
         */
        aesEncrypt(txt) {
            let cipher = crypto.createCipheriv('aes-256-cbc', this.bank_account.key, this.bankLoginForm.iv);
            let encrypted = cipher.update(txt, 'utf8', 'base64');
            encrypted += cipher.final('base64');
            return encrypted;
        },
        /**
         * Client side decryption
         */
        aesDecrypt(txt) {
            let decipher = crypto.createDecipheriv('aes-256-cbc', this.bank_account.key, this.bankLoginForm.iv);
            let decrypted = decipher.update(txt, 'base64', 'utf8');
            return (decrypted + decipher.final('utf8'));
        },
        /**
         * Generate random IV
         */
        generateIV(length) {
            var result = '';
            var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            var charactersLength = characters.length;
            for ( var i = 0; i < length; i++ ) {
                result += characters.charAt(Math.floor(Math.random() * charactersLength));
            }
            return result;
        },

        /**
         * Set focus on input when edit button is clicked
         */
        setFocus(val) {
            this.$refs[val].$el.getElementsByTagName('input')[0].focus();
        },

        editIncome() {
            this.edittingIncome = true;
            // window.utag.link({
            //     event_name: 'ui_interaction',
            //     page_name: 'vodapay: vodalend compare: confirm income details',
            //     page_section: 'vodalend compare',
            //     page_channel: 'mobile app',
            //     visitor_login_status: 'logged in',
            //     page_type: 'webview',
            //     link_id: 'vodapay: vodalend compare: change',
            // });
        },
        cancelPop() {
            this.cancelPopup = false;
            //  window.utag.link({
            //     event_name: 'ui_interaction',
            //     page_name: 'vodapay: vodalend compare: confirm income details',
            //     page_section: 'vodalend compare',
            //     page_channel: 'mobile app',
            //     visitor_login_status: 'logged in',
            //     page_type: 'webview',
            //     link_id: 'vodapay: vodalend compare: thats correct',
            // });
        },

        /**
         * Listen for online banking events
         */
        listenOnlineBankingTransactionEvents() {
            let self = this;

            if(self.session_code) {
                /**
                 * Login success event
                 */
                window.Echo.channel(`login-success.${self.session_code}`)
                    .listen('.login-success', () => {
                        self.isLoggedIn = true;
                        setTimeout(() => {
                            self.loadingMessage = 'We are busy fetching your accounts. This may take up to 90 seconds.';
                            self.counterMessage = 'Fetching a list of your accounts.';
                            if (self.bankAccount.repeat) {
                                self.loadingMessage = 'We’re now collecting your three most recent bank statements. This may take up to 90 seconds.';
                                self.counterMessage = 'Collecting statements.';
                            }
                            self.otpSubmitted = true;
                            clearInterval(this.interval);
                            self.loginTimer = false;
                            self.loadingTimeCountDown(this.bank_account.accounts_loading);
                            self.loading = true;
                        }, 2000);
                    });

                /**
                 * OTP sent event
                 */
                window.Echo.channel(`otp-sent.${self.session_code}`)
                    .listen('.otp-sent', () => {
                        self.loading = true;
                        self.showOtp = true;
                        self.loginTimer = false;
                    });

                /**
                 * Get transactions on success
                 */
                window.Echo.channel(`transactions-extracted.${self.session_code}`)
                    .listen('TransactionsExtractedEvent', () => {
                        self.stopOnlineBankingEvents();
                        clearTimeout(self.timer);
                        clearInterval(this.interval);
                        this.counterDuration = null;
                        this.submitStatements();
                    });

                /**
                 * Notify user on error
                 */
                window.Echo.channel(`online-banking-error.${self.session_code}`)
                    .listen('OnlineBankingErrorEvent', (e) => {
                        self.stopOnlineBankingEvents();
                        self.statementErrors.error = e.message;
                        self.loading = false;
                        self.initialLoading = false;
                        self.status_code = 'bank_scraper_error';
                        self.otpSubmitted = false;
                        self.showAccountsModal = false;
                        self.loadingMessage = null;
                        self.counterMessage = null;
                        clearTimeout(self.timer);
                    });

                /**
                 * Notify user on transactions failed
                 */
                window.Echo.channel(`below-threshold.${self.session_code}`)
                    .listen('.below-threshold', (e) => {
                        self.stopOnlineBankingEvents();
                        self.statementErrors.error = e.message;
                        self.loading = false;
                        self.initialLoading = false;
                        self.failedAttempts++;
                        self.status_code = 'bank_scraper_error';
                        self.otpSubmitted = false;
                        self.showAccountsModal = false;
                        self.loadingMessage = null;
                        self.counterMessage = null;
                        clearTimeout(self.timer);
                    });

                /**
                 * Allow users to select accounts to scrape
                 */
                window.Echo.channel(`online-banking-accounts.${self.session_code}`)
                    .listen('OnlineBankingAccountsEvent', () => {
                        this.getBankAccounts();
                    });

                /**
                 * listen for account verification success
                 */
                window.Echo.channel(`account-verification-success.${self.session_code}`)
                    .listen('.account-verified', () => {
                        clearTimeout(self.timer);
                        self.completed = true;
                        self.loading = false;
                        self.initialLoading = false;
                        self.otpSubmitted = false;
                        self.statementErrors.error = '';
                        self.loadingMessage = null;
                        self.counterMessage = null;
                        this.submitStatements();
                    });
            }
        },

        /**
         * Stop listening for online events
         */
        stopOnlineBankingEvents() {
            window.Echo.leave(`statement-download-success.${this.session_code}`);
            window.Echo.leave(`account-verification-success.${this.session_code}`);
            window.Echo.leave(`otp-sent.${this.session_code}`);
            window.Echo.leave(`login-success.${this.session_code}`);
            window.Echo.leave(`online-banking-accounts.${this.session_code}`);
            window.Echo.leave(`below-threshold.${this.session_code}`);
            window.Echo.leave(`online-banking-error.${this.session_code}`);
            window.Echo.leave(`transactions-extracted.${this.session_code}`);
        },

        changeUploadMethod(){
            this.$store.dispatch('state/updateStatus','select-bank')
            this.$router.push({ name: 'select-bank'})
        },

        /**
         * Handle bank login
         */
        async bankLogin() {
            this.$store.dispatch('state/setIsLoggedIn',true)
            if (this.initialLoading){
                return;
            }
            window.utag.link({
                event_name: 'ui_interaction',
                page_name: 'vodapay: vodalend compare:bank statements online login',
                page_section: 'vodalend compare',
                page_channel: 'mobile app',
                visitor_login_status: 'logged in',
                page_type: 'webview',
                link_id: 'vodapay: vodalend compare: get statements',
            });
            this.fetchingAccounts = true;
            let self = this;
            self.listenOnlineBankingTransactionEvents();
            self.bankLoginForm.bank = self.bank_account.bank;
            self.bankLoginForm.session_code = self.session_code;
            self.bankLoginForm.slug = process.env.VUE_APP_GATHR_TENANT;
            self.initialLoading = true;
            self.loginError = null;
            self.loginPopup = true;
            self.bankLoginForm.iv = self.generateIV(16);
            self.bankLoginForm.credentials = self.aesEncrypt(JSON.stringify(self.bankLoginForm.credentials));
            window.utag.view({
                event_name: 'page_view',
                page_name: 'vodapay: vodalend compare: bank statements online login loading',
                page_section: 'vodalend compare',
                page_channel: 'mobile app',
                visitor_login_status: 'logged in',
                page_type: 'webview',
            });
            self.handleTimeout('bank_scraper_error', 'There was an error connecting to your bank. Please try again.');
            setTimeout(() => {
                self.bankLoginForm.credentials = JSON.parse(self.aesDecrypt(self.bankLoginForm.credentials));
            }, 500);

            try {
                await this.$store.dispatch('banking/login', self.bankLoginForm);
                self.counterMessage = 'Confirming your bank login details.';
                self.loginTimer = true;
                let loadingTime = 30;
                if (self.bank_account.has_otp) {
                  loadingTime = 60;
                }
                if (self.bank_account.bank === 'capitec') {
                  loadingTime = 60;
                }
                self.loadingTimeCountDown(loadingTime);
            } catch (error) {
                let message = error.response.data.errors[0].message;
                let failedMessage = 'There was an error connecting to your bank. Please try again.'
                window.utag.view({
                    event_name: 'page_view, event_error',
                    page_name: 'vodapay: vodalend compare: error connecting to bank',
                    page_section: 'vodalend compare',
                    page_channel: 'mobile app',
                    visitor_login_status: 'logged in',
                    page_type: 'webview',
                    event_error_name: message,
                    event_error_code: error.response.data.errors[0].code,
                    event_error_type: message
                });
                if (message) {
                    if (message.toLowerCase() !== 'there was an error connecting to your bank. please try again.' || message.toLowerCase() !== 'server error') {
                        if (error.response.data.errors[0].code == 'login_failed') {
                            if (self.loginFailedAttempts == 0){
                                self.loginError = 'Invalid login details. Please try again.';
                            } else if (self.loginFailedAttempts == 1){
                                self.loginError = '2nd Failed login attempt.';
                            } else if (self.loginFailedAttempts == 2){
                                self.loginError = '3rd Failed login attempt.';
                            }
                        } else {
                            self.loginError = message;
                        }

                        self.loginFailedAttempts++
                        self.failedAttempts++
                        self.bankLoginForm.credentials.password = null;
                        self.bankLoginForm.resubmit = true;
                    } else {
                        if (message.toLowerCase() === 'server error') {
                            message = 'There was an error connecting to your bank. Please try again.'
                        }
                        self.failedAttempts++
                        self.statementErrors.error = message;
                        self.status_code = 'bank_scraper_error';
                    }
                    window.utag.view({
                        event_name: 'page_view, event_error',
                        page_name: 'vodapay: vodalend compare: error connecting to bank',
                        page_section: 'vodalend compare',
                        page_channel: 'mobile app',
                        visitor_login_status: 'logged in',
                        page_type: 'webview',
                        event_error_name: message,
                        event_error_code: error.response.data[0].code,
                        event_error_type: message
                    });
                } else {
                    self.loginError = failedMessage;
                    self.loginFailedAttempts++
                    self.failedAttempts++
                    self.bankLoginForm.credentials.password = null;
                    self.bankLoginForm.resubmit = true;
                    self.statementErrors.error = failedMessage;
                    self.status_code = 'bank_scraper_error'
                }
                window.utag.view({
                    event_name: 'page_view, event_error',
                    page_name: 'vodapay: vodalend compare: error connecting to bank',
                    page_section: 'vodalend compare',
                    page_channel: 'mobile app',
                    visitor_login_status: 'logged in',
                    page_type: 'webview',
                    event_error_name: message,
                    event_error_code: error.response.data[0].code,
                    event_error_type: message
                });

                clearTimeout(self.timer);
                self.otpSubmitted = false;
                self.loading = false;
                self.initialLoading = false;
                self.bankLoginForm.credentials = JSON.parse(self.aesDecrypt(self.bankLoginForm.credentials));

            }
        },
        /** Submit OTP */
        async submitOtp() {
            let self = this;
            self.otpSubmitted = true;
            try {
                await this.$store.dispatch('banking/updateOtp', self.otpForm);
                self.showAccountsModal = false;
                self.loading = true;
                self.otpSubmitted = true;
                self.showOtp = false;
                clearInterval(self.interval);
                self.loginTimer = false;
                self.counterDuration = null;
                if (self.bankAccount.repeat) {
                    let loadingTime = self.loginSession.statements_loading
                    loadingTime += ((10-1) * 20);
                    self.loadingTimeCountDown(loadingTime);
                    self.loadingMessage = 'We’re now collecting your three most recent bank statements. This may take up to 90 seconds.';
                    self.counterMessage = 'Collecting statements.';
                } else {
                    self.loadingTimeCountDown(30);
                    self.loadingMessage = 'We are busy confirming your OTP. This may take up to 90 seconds.';
                    self.counterMessage = 'Confirming your OTP.';
                }
            } catch (error) {
                self.otpSubmitted = false;
            }
        },
        /** Timeout if there is no response within 3 minutes */
        handleTimeout(code, message) {
            let self = this;
            self.timer = setTimeout(() => {
                self.statementErrors.error = message;
                self.loading = false;
                self.initialLoading = false;
                self.status_code = code;
                self.otpSubmitted = false;
            }, 180000);
        },
        /**
         * update online accounts
         */
        async updateBankAccounts(e) {
            e.preventDefault();
            let self = this;
            let loadingTime = this.loginSession.statements_loading;
            let numAccounts = this.accountsForm.bank_accounts.length;
            let extraTime = 10;
            if (self.bank_account.has_otp) {
                extraTime = 20;
            }
            if (!numAccounts) return;
            if (numAccounts > 1) {
              loadingTime += ((numAccounts-1) * extraTime);
            }

            try {
                window.utag.link({
                    event_name: 'ui_interaction',
                    page_name: 'vodapay: vodalend compare: bank statements select day to day accounts',
                    page_section: 'vodalend compare',
                    page_channel: 'mobile app',
                    visitor_login_status: 'logged in',
                    page_type: 'webview',
                    link_id: 'vodapay: vodalend compare: confirm',
                });
                self.showAccountsModal = false;
                self.loading = true;
                window.utag.view({
                    event_name: 'page_view',
                    page_name: 'vodapay: vodalend compare: bank statements select day to day accounts loading',
                    page_section: 'vodalend compare',
                    page_channel: 'mobile app',
                    visitor_login_status: 'logged in',
                    page_type: 'webview',
                });
                self.otpSubmitted = true;
                clearInterval(self.interval);
                self.counterDuration = null;
                self.loadingTimeCountDown(loadingTime);
                self.loadingMessage = 'We’re now collecting your three most recent bank statements. This may take up to 90 seconds.';
                self.counterMessage = 'Collecting statements.';
                self.loginTimer = false;
                await this.$store.dispatch('banking/updateBankAccounts', self.accountsForm);
            } catch (error) {
                console.log(error)
            }
        },
        /**
         * Get online accounts
         */
        async getBankAccounts() {
            let self = this;
            try {
                await this.$store.dispatch('banking/getBankAccounts');
                if (this.accounts.length >= 1) {
                    let accountNumber = this.accounts[0].accountNumber;
                    for (let i = 0; i < this.accounts.length; i++) {
                        if (this.accounts[i].accountName.toLowerCase().includes('current')) {
                            accountNumber = this.accounts[i].accountNumber;
                        }
                    }
                    this.accountsForm.bank_accounts = [accountNumber];
                }
                setTimeout(() => {
                    self.fetchingAccounts = false;
                    self.showAccountsModal = true;
                    self.loadingMessage = null;
                    self.counterMessage = null;
                    self.loading = false;
                    clearInterval(self.interval);
                    self.counterDuration = null;
                    self.autoSubmitAccounts();
                }, 3000);
                 window.utag.view({
                    event_name: 'page_view',
                    page_name: 'vodapay: vodalend compare: bank statements select day to day accounts',
                    page_section: 'vodalend compare',
                    page_channel: 'mobile app',
                    visitor_login_status: 'logged in',
                    page_type: 'webview',
                });
            } catch (error) {
                console.log(error)
            }
        },

        closeLoginPopup(){
            this.loginPopup = false;
        },

        closeInitialLoginPopup(){
            this.initialLoginPopup = false
        },
        /**
         * Continue to next module or change upload option
         */
        retryLogin() {
            window.utag.link({
                event_name: 'ui_interaction',
                page_name: 'vodapay: vodalend compare: error connecting to bank',
                page_section: 'vodalend compare',
                page_channel: 'mobile app',
                visitor_login_status: 'logged in',
                page_type: 'webview',
                link_id: 'vodapay: vodalend compare: try again',
            });
            this.initialLoginPopup = true;
            this.loginTimer = false;
            this.bankLoginForm.resubmit = true;
            this.statementErrors.error = '';
            this.loading = false;
            this.initialLoading = false;
            this.otpSubmitted = false;
            this.bankLoginForm.credentials = {
                    username: null,
                    pin: null,
                    password: null,
                };
        },

        cancel(){
            // window.utag.link({
            //     event_name: 'ui_interaction',
            //     page_name: 'vodapay: vodalend compare: confirm expense details',
            //     page_section: 'vodalend compare',
            //     page_channel: 'mobile app',
            //     visitor_login_status: 'logged in',
            //     page_type: 'webview',
            //     link_id: 'vodapay: vodalend compare: cancel',
            // });
            this.cancelPopup = true;
            // window.utag.view({
            //     event_name: 'page_view',
            //     page_name: 'vodapay: vodalend compare: confirm income details',
            //     page_section: 'vodalend compare',
            //     page_channel: 'mobile app',
            //     visitor_login_status: 'logged in',
            //     page_type: 'webview',
            // });
        },

        changeBankAccount(){
            this.$router.push({name:'select-bank'})
        },

        selectAccount(val){
            let accounts = this.accountsForm.bank_accounts;
            let index = accounts.indexOf(val);
            if (index !== -1) {
                accounts.splice(index, 1);
                this.accountsForm.bank_accounts = accounts;
                return
            }
            accounts.push(val);
        },

        async submitStatements(){
             window.utag.view({
                event_name: 'page_view',
                page_name: 'vodapay: vodalend compare: bank statements online login loading',
                page_section: 'vodalend compare',
                page_channel: 'mobile app',
                visitor_login_status: 'logged in',
                page_type: 'webview',
            });
            clearInterval(this.interval);
            this.counterDuration = null;
            this.loadingMessage = 'Statements collected! We are now checking your bank statements.';
            this.counterMessage = 'Checking statements.';
            this.loginTimer = false;
            this.loadingTimeCountDown(10);
            try {
                this.loading = true;
                const { data } = await api.getSummary(this.bankAccount.id);
                this.summaryData = data;

                setTimeout(async () => {
                  let averages      = this.summaryData.averages;
                  let monthsSummary = this.summaryData.months;
                  let expenses      = averages.average_expenses;
                  let grossIncome   = 0;
                  let source        = '';
                  let month         = '';
                  if (Array.isArray(monthsSummary)) {
                    for (let i = 0; i < monthsSummary.length; i++) {
                      if (monthsSummary[i].main_income > 0 && monthsSummary[i].fullMonth && grossIncome === 0) {
                        grossIncome = monthsSummary[i].main_income;
                        source = monthsSummary[i].main_income_transaction;
                        month = monthsSummary[i].month;
                      }
                    }

                    if (grossIncome === 0) {
                      grossIncome = monthsSummary[0].incomes;
                      source = monthsSummary[0].main_income_transaction;
                      month = monthsSummary[0].month;
                    }
                  }
                  let txData = {
                      income:grossIncome,
                      expenses:expenses,
                      income_source:source,
                      month:month
                  }
                  await this.$store.dispatch('application/updateTransactionData',txData)

                  
                  this.loading = false;
                  this.loadingMessage = null;
                  this.counterMessage = null;
                  this.completed = true;
                  this.loading = true;
                  this.initialLoading = false;
                  this.otpSubmitted = false;
                  this.statementErrors.error = '';
                  await this.$store.dispatch('banking/getTransactions');
                  this.$router.push('select-income')
                }, 5000);
                window.utag.view({
                    event_name: 'page_view',
                    page_name: 'vodapay: vodalend compare: confirm income details',
                    page_section: 'vodalend compare',
                    page_channel: 'mobile app',
                    visitor_login_status: 'logged in',
                    page_type: 'webview',
                });
            } catch (error) {
                clearInterval(this.interval);
                this.loadingMessage = null;
                this.counterMessage = null;
                this.counterDuration = null;
                this.loading = false;
                this.error = error.response && Array.isArray(error.response.data) && error.response.data[0] ? error.response.data[0] : { code: 'technical_error'};
                this.step = 'error'
            }

        },

        selectTransaction(transaction) {
            this.selectedTransaction = transaction.id
            this.incomeChangeForm = {
                income_source: transaction.description,
                id: transaction.id,
                income: transaction.amount
            }
        },

        redirectToFaqs(){
            window.utag.link({
                event_name: 'ui_interaction',
                page_name: 'vodapay: vodalend compare:bank statements online login',
                page_section: 'vodalend compare',
                page_channel: 'mobile app',
                visitor_login_status: 'logged in',
                page_type: 'webview',
                link_id: 'vodapay: vodalend compare: faqs',
            });
            this.$store.dispatch('state/updateStatus', 'faqs')
            this.$router.push('faqs/online')
        },

        reportIssue(){
             window.utag.link({
                event_name: 'ui_interaction',
                page_name: 'vodapay: vodalend compare: error connectting to bank',
                page_section: 'vodalend compare',
                page_channel: 'mobile app',
                visitor_login_status: 'logged in',
                page_type: 'webview',
                link_id: 'vodapay: vodalend compare: report issue',
            });
            this.$store.dispatch('state/updateStatus','report-issue')
            this.$router.push({ name: 'report-issue',params: { type: 'online' } })
        },

        loadingTimeCountDown(duration) {
            let currentDuration = duration;
            let min = 0;
            if (duration) {
                this.interval = setInterval(() => {
                    min = 0;
                    currentDuration = duration;
                    if (duration > 60) {
                      min = 1;
                      currentDuration = duration - 60;
                    }
                    currentDuration--;
                    duration--;
                    if (currentDuration >= 0) {
                      this.counterDuration = currentDuration < 10 ? `0${min}:0${currentDuration}` : `0${min}:${currentDuration}`;
                    }
                }, 1100);
            }
        },

        autoSubmitAccounts() {
          let e = new Event('click');
          setTimeout(() => {
            if (!this.loading) {
              this.updateBankAccounts(e);
            }
          }, 12000);
        }

    }
}
</script>

<style lang="scss" scoped>
._loading-screens {
    display: flex;
    position: relative;
    top: 35%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    p{
        color: white !important;
    }
}

.el-input__inner {
    padding-left: 0 !important;
    border-bottom: 1px solid #8D8D8D;
    border: unset;
}
</style>
