<template>
    <div class="_spa-container" style="height: 100%;">
         <div style="height: 100%;display: flex;flex-direction: column;justify-content: space-between;">
            <div>
                <div class="_wording-container">
                    <img src="/assets/spa/fail-envelop.svg" style="width: 100px;"/>
                    <span class="_title" style="margin-top: 50px;">We’re sorry</span>
                    <span class="_text" style="padding:0px 25px">Unfortunately, all of our lenders require you to be employed or self-employed. They need to know you receive a regular income in order to see whether you’ll be able to pay back the loan.</span>
                </div>
            </div>
                <secondary-button id="secondary_nav" style="width:100%;font-size:17px" @click="goBack">Mistake? Click here if you are employed</secondary-button>
            <div class="_button-container">
                <secondary-button id="secondary_nav" style="width:40%" @click="redirectToFaqs">FAQ's</secondary-button>
                <primary-button id="primary_nav" style="width: 60%;justify-content: center;margin-left: 10px;margin-bottom: 10px;" @click="exitApp">Exit</primary-button>
            </div>
            </div>
        <el-dialog
            :visible.sync="dialogVisible"
            :modal-append-to-body="false"
            :append-to-body="true"
            :close-on-press-escape="false"
            :lock-scroll="true"
            :show-close="false"
            :close-on-click-modal="false"
            size="small"
            custom-class="_consent-dialog"
        >
            <img src="/assets/spa/lightbulb.svg"/>
            <span style="text-align:center">Please tap the exit icon on the top right of your screen to go to the VodaPay app.</span>
            <secondary-button id="secondary_nav" @click="dialogVisible = false" style="width:100%;color:#C9CCD4">Got It <img src="/assets/spa/icon-right.svg" style="height: 10px;width: auto;margin: 0px;margin-left: 10px;"/></secondary-button>
        </el-dialog> 
    </div>
</template>

<script>

export default {
    data(){
        return {
            dialogVisible:false,
        }
    },
    methods:{
        goBack(){
            this.$store.dispatch('state/updateStatus', 'primary-income')
            this.$router.go(-1)
        },
        redirectToFaqs(){
            this.$store.dispatch('state/updateStatus', 'faqs')
            this.$router.push('/faqs/online')
        },
        exitApp(){

        }
    },

}
</script>