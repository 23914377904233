import axios from "axios";
import { orderedPartners } from '../../helpers';
const apiUrl = process.env.VUE_APP_ENGINE_URL;
const apiKey = process.env.VUE_APP_ENGINE_KEY;

// initial state
const state = () => ({
    visiblePartners: [],
    selectedOffer: {},
    offers: [],
})

const getters = {
    selectedOffer: (state) => state.selectedOffer,
    offers: (state) => state.offers,
    visiblePartners: (state) => state.visiblePartners,
}

const actions = {
    setSelectedOffer({ commit }, offer) {
        commit("setSelectedOffer", offer);
    },
    async fetchOffers({ commit, rootState }) {
        commit("application/confirmPopi", true, { root: true });
        let personalLoan = rootState.application.personalLoan;
        if (personalLoan.net_income == null) {
          personalLoan.net_income = personalLoan.gross_income;
        }
        personalLoan.application_method = rootState.application.type
        personalLoan.bankAccount = rootState.application.bankAccount;
        try {
          let { data } = await axios.post(
            apiUrl + "/api/v1/offer",
            personalLoan,
            {
              headers: { Authorization: "Bearer " + apiKey },
            }
          );
          let redirect = false;
          if (data.matches.length > 0) {
            await commit("setOffers", data);
            redirect = "display-offers";
          } else {
            redirect = "failed-offers";
          }
          let visiblePartners = orderedPartners(data.matches, rootState.application.type).filter(
            function(offer) {
              return offer.live_score != -2;
            }
          ).length;
          console.log(visiblePartners)
          await commit("setVisiblePartners", visiblePartners);
    
          if (visiblePartners == 0) {
            redirect = "failed-offers";
          }
          return redirect;
        } catch (e) {
          return "technical-error";
        }
      },
}

const mutations = {
    setSelectedOffer(state, offer) {
        state.selectedOffer = offer;
      },
      setOffers(state, offers) {
        state.offers = offers;
      },
      setVisiblePartners(state, partners) {
        state.visiblePartners = partners;
      },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}

